import React, {useState, useCallback} from 'react'
import Input from '../../Input';
import InputMask from '../../InputMask';
import SelectContratoPG from '../../SelectContratoPg';
import Price from "../../../../img/contract/price.svg"
import CurrencyInput from '../../CurrencyInput';

const Step_3 = () => {
  const [formaPagamento, setFormaPagamento] = useState([
    {option: "À vista", name:"À vista"},
    {option: "Parcelado", name:"Parcelado"},
  ])
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);


  const handleValueChange = useCallback(val => {
    setValue(val);
  }, []);
  const handleValueChange1 = useCallback(val => {
    setValue1(val);
  }, []);
  const handleValueChange2 = useCallback(val => {
    setValue2(val);
  }, []);

  return (
    <div className="step-1">
      <div className="step-header">
      <img src={Price} alt="Forma de pagamento" />
        <h4 className="mb-0 ml-5">Forma de pagamento</h4>
      </div>
      <div className="form-row w-100">
        <div className="col-md-5">
          <SelectContratoPG 
            name="form_pagamento" 
            label="Forma de pagamento acordada*" 
            data={formaPagamento}
          />
        </div>
        <div className="col-md-7">
          <CurrencyInput max={100000000} onValueChange={handleValueChange} value={value} label="Valor TOTAL acordado" name="form_vlr_total" id=""/>
        </div>
      </div>  
      <p>Caso a forma de pagamento escolhida seja parcelada, preencha os campos abaixo:</p>
      <div className="form-row w-100">
        <div className="col-md-6">
        <CurrencyInput max={100000000} onValueChange={handleValueChange1} value={value1} label="Valor da primeira parcela" name="form_vlr_parcelado" id=""/>
        </div>
        <div className="col-md-6">
        <CurrencyInput max={100000000} onValueChange={handleValueChange2} value={value2} label="Valor da segunda parcela" name="form_vlr_seg_parcelado" id=""/>
        </div>
      </div>  
      <p>Período acordado para a realização do serviço (especificar datas):</p>
      <div className="form-row w-100">
        <div className="col-md-6">
        <Input
            name="form_prazo"
            label="Período/Datas*"
          />
        </div>
      </div> 
    </div>
  )
}

export default Step_3;