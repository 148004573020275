import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import card1 from "../img/client/card-1.webp";
import card2 from "../img/client/card-2.webp";
import card3 from "../img/client/card-3.webp";
import card4 from "../img/client/card-4.webp";
import card5 from "../img/client/card-5.webp";
import card6 from "../img/client/card-6.webp";
import card7 from "../img/client/card-7.webp";
import card8 from "../img/client/card-8.webp";
import card9 from "../img/client/card-9.webp";
import { Link } from "react-router-dom";
const Prices = () => {
  const options = {
    loop: true,
    autoplay: true,
    smartSpeed: 1000,
    dots: false,
    nav: false,
    autoplayTimeout: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 5,
      },
    },
  };
  return (
    <>
      <div id="merox-client-slider" className="merox-client-slider pt-20 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-10 col-md-12 mx-auto">
            <div className="section-title">
                <h4>Formas de pagamento</h4>
              </div>
              <div className="all-client-slider ">
                <OwlCarousel className="owl-carousel owl-theme" {...options}>
                
                  <div className="single-client">
                    <img src={card1} alt="img" className="img-fluid" />
                  </div>
      
                  <div className="single-client">
                    <img src={card2} alt="img" className="img-fluid" />
                  </div>

                  <div className="single-client">
                    <img src={card3} alt="img" className="img-fluid" />
                  </div>

                  <div className="single-client">
                    <img src={card4} alt="img" className="img-fluid" />
                  </div>

                  <div className="single-client">
                    <img src={card5} alt="img" className="img-fluid" />
                  </div>

                  <div className="single-client">
                    <img src={card6} alt="img" className="img-fluid" />
                  </div>

                  <div className="single-client">
                    <img src={card7} alt="img" className="img-fluid" />
                  </div>

                  <div className="single-client">
                    <img src={card8} alt="img" className="img-fluid" />
                  </div>

                  <div className="single-client">
                    <img src={card9} alt="img" className="img-fluid" />
                  </div>

                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Prices;
