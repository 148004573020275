import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, withRouter } from "react-router";
import Utilidade from "../context/Unidades"
import BtInstragran from "./ButtonInstagram";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [whidth, setWhidth] = useState(false);
  const [brand, setBrand] = useState({})
  const {uf} = useParams()

  useEffect(() => {
    setBrand(Utilidade.renderRegiao(uf))
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });

    setWhidth(window.innerWidth <= 1200);

  }, []);
  // form class add & delete
  const classadd = () => {
    document.querySelector("#search-btn");
    document.querySelector(".search-form-area").classList.add("active");
  };

  const classdelet = () => {
    document.querySelector("#close-btn");
    document.querySelector(".search-form-area").classList.remove("active");
  };

  return (
    <>
      <header>
        <div
          className={
            scroll ? "header-area sticky animated slideInDown" : whidth ? "header-area sticky" : "header-area"
          }
        >
          <div className="container">

            <div className="row">
              <div className="col-12">
                <div className="header-top-right float-right">
                  <ul className="header-search">
                    <li>
                      <BtInstragran />
                    </li>
                  </ul>
                </div>
                <div className="header-top-right float-right">
                  <ul className="header-search">
                    <li>
                      <Link id="search-btn" to={`${process.env.PUBLIC_URL + "/" + uf + "/contact"}`}>
                        {/* <i className="fa fa-phone"></i> */}
                        Fale Conosco
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="merox-main-menu-area">

                  <nav className="navbar navbar-expand-xl navbar-light main-menu">
                    <Link
                      className="navbar-brand p-0 "
                      to={`${process.env.PUBLIC_URL + "/" + uf}`}
                    >
                      <img src={brand.logo} alt="logo" class="logo-main"/>
                    </Link>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="fa fa-align-left"></i>
                    </button>

                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto mr-auto">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL + "/" + uf}`}
                          >
                            Home
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL + "/" + uf + "/curso"}`}
                          >
                            Curso de detetive
                          </Link>
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Serviços
                          </Link>
                          <div
                            className="dropdown-menu fade-down"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              // to="#"
                              to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_conjugal"}`}
                            >
                              Investigação conjugal
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_filhos"}`}
                            >
                              Investigação de filhos
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_empresarial"}`}
                            >
                              Investigação empresarial
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/" + uf + "/localizacao_pessoas"}`}
                            >
                              Encontrar Pessoas
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/" + uf + "/golpista_amoroso"}`}
                            >
                              Golpista Amoroso
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_cuidador_idoso"}`}
                            >
                              Investigação de Cuidador de Idosos
                            </Link>
                          </div>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL + "/" + uf + "/about"}`}
                          >
                            Sobre nós
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default withRouter(Header);
