import React from "react";
import blogimg1 from "../img/blog/6.webp";
import blogimg2 from "../img/blog/3.webp";
import { Link, useParams } from "react-router-dom";
const Blog = () => {
  const {uf} = useParams()
  return (
    <>
      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
                <h4>Investigação </h4>
                <h3>EMPRESARIAL</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row container-fluid">
          <div className="col-sm blog-img">
            <img src={blogimg1} alt="blog-img" />
          </div>
          <div className="col-sm">
            <ul className="about-content-list mb-5">
              <li>
                <i className="fa fa-check"></i> <Link>Fraudes Corporativas</Link>
              </li>
              <li>
                <i className="fa fa-check"></i> <Link>Investigação de Funcionários</Link>
              </li>
              <li>
                <i className="fa fa-check"></i> <Link>Investigação de Executivos</Link>
              </li>
              <li>
                <i className="fa fa-check"></i> <Link>Investigação de Fornecedores</Link>
              </li>
              <li>
                <i className="fa fa-check"></i> <Link>Celular, Computadores e Diversos Equipamentos</Link> 
              </li>
            </ul>            
          </div>
          <div className="col-sm blog-img">
            <img src={blogimg2} alt="blog-img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
