import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Medalha from "../img/app/medalha.webp";
// Imports Mapa
import MapaSP from "../img/mapas/mapaSP.jpeg"
import MapaRS from "../img/mapas/mapaRS.jpeg"
import MapaBA from "../img/mapas/mapaBA.jpeg"
import MapaMG from "../img/mapas/mapaMG.jpeg"
import MapaMT from "../img/mapas/mapaMT.jpeg"
import MapaGO from "../img/mapas/mapaGO.jpeg"
import MapaSC from "../img/mapas/mapaSC.jpeg"
import MapaRJ from "../img/mapas/mapaRJ.jpeg"
import MapaPR from "../img/mapas/mapaPR.jpeg"
import MapaBR from "../img/mapas/mapaBR.jpeg"


const MainsliderCel = ({ locale }) => {
  const { uf } = useParams()
  const [width, setWidth] = useState(1700)

  const [showBox, setShowBox] = useState(true);

  useEffect(() => {
    setWidth(window.innerWidth)

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    if (window.pageYOffset > window.innerHeight / 2) {
      setShowBox(false);
    } else {
      setShowBox(true);
    }
  }

  // Imagem Mapa
  var pathImagem = MapaBR;
  var pathUF;

  // console.log(uf);
  if (uf === "SP") {
    pathImagem = MapaSP;
    pathUF = 'São Paulo';
  } else if (uf === "mg") {
    pathImagem = MapaMG;
    pathUF = 'Minas Gerais';
  } else if (uf === "rs") {
    pathImagem = MapaRS;
    pathUF = 'Rio Grande do Sul';
  } else if (uf === "ba") {
    pathImagem = MapaBA;
    pathUF = 'Bahia';
  } else if (uf === "mt") {
    pathImagem = MapaMT;
    pathUF = 'Mato Grosso';
  } else if (uf === "go") {
    pathImagem = MapaGO;
    pathUF = 'Goiás';
  } else if (uf === "sc") {
    pathImagem = MapaSC;
    pathUF = 'Santa Catarina';
  } else if (uf === "rj") {
    pathImagem = MapaRJ;
    pathUF = 'Rio de Janeiro';
  } else if (uf === "pr") {
    pathImagem = MapaPR;
    pathUF = 'Paraná';
  }

  return (
    <div className="mobile">
      <div id="merox-slider-area" >
        <div className="banner-mobile">
          {/* <h1>Descubra a verdade AGORA</h1> */}
        </div>
        {
          (uf == "sp")
            ? <img src={Medalha} alt="img"
              className="medalha" />
            : null
        }
      </div>
      <div>
        <div className="container" >
          <div className="row">
            <div className="col-xl-12 text-center pt-1">
              <div className="atendimentos">
                {locale.uf === 'ge'
                  ? <h2>Atendimento em todo <b style={{ color: "#9B0008", textTransform: "uppercase" }}>Brasil</b></h2>
                  : <h2>Atendimento em  <b style={{ color: "#9B0008", textTransform: "uppercase" }}>{pathUF}</b></h2>
                }
                {/* <a  style={{opacity: showBox ? 1 : 0, pointerEvents: showBox ? "auto" : "none"}}
                  href={`https://api.whatsapp.com/send?phone=${locale.telefone[1]}&text=Ol%C3%A1!%20Gostaria%20de%20entrar%20em%20contato%20com%20voc%C3%AA.`} target="_blank">
                  <div class="suspense">
                    <h1>Detetive Particular {locale.uf_conector} {locale.uf === 'ge' ? ' todo o Brasil' : locale.uf}</h1>
                    <h3>Detetive Particular em São Paulo</h3>
                    <p><u>Clique aqui</u> e fale conosco imediatamente!</p>
                  </div>
                </a> */}
                {/* <h2>
                  Atendimento<br /> 
                  <b style={{ color: "#9B0008", textTransform: "uppercase" }}>{locale.uf} </b>
                  e em todo o<b style={{ color: "#9B0008", textTransform: "uppercase" }}> Brasil</b>
                </h2> */}

              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 text-center">
              <img src={pathImagem} height={200} width={200} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default MainsliderCel;