import React, {useState} from 'react'
import Input from '../../Input';
import InputMask from '../../InputMask';
import SelectContrato from '../../SelectContrato';

import Spy from '../../../../img/contract/spy.svg'
import axios from "axios"
import Loding from '../../../Loding/loading';

const Step_4 = () => {
  const [ufs, setUFs] = useState([
    {option: "AC", name: "Acre"},
    {option: "AL", name: "Alagoas"},
    {option: "AM", name: "Amazonas"},
    {option: "AP", name: "Amapá"},
    {option: "BA", name: "Bahia"},
    {option: "CE", name: "Ceará"},
    {option: "DF", name: "Distrito Federal"},
    {option: "ES", name: "Espírito Santo"},
    {option: "GO", name: "Goiás"},
    {option: "MA", name: "Maranhão"},
    {option: "MG", name: "Minas Gerais"},
    {option: "MS", name: "Mato Grosso do Sul"},
    {option: "MT", name: "Mato Grosso"},
    {option: "PA", name: "Pará"},
    {option: "PB", name: "Paraíba"},
    {option: "PE", name: "Pernambuco"},
    {option: "PI", name: "Piauí"},
    {option: "PR", name: "Paraná"},
    {option: "RJ", name: "Rio de Janeiro"},
    {option: "RN", name: "Rio Grande do Norte"},
    {option: "RO", name: "Rondônia"},
    {option: "RR", name: "Roraima"},
    {option: "RS", name: "Rio Grande do Sul"},
    {option: "SC", name: "Santa Catarina"},
    {option: "SE", name: "Sergipe"},
    {option: "SP", name: "São Paulo"},
    {option: "TO", name: "Tocantins"},
  ]);
  const [address, setAddress] = useState({
    logradouro: undefined,
    bairro: undefined,
    cidade: undefined,
    uf: undefined
  })
  const [load, setLoad] = useState(false)
  const [addressCommercial, setAddressCommercial] = useState({})

  const handleCep = (event) => {
    setLoad(true)
    const cepConvert = event.target.value.replace('.', '')
    const api = axios.create({
      baseURL: "https://viacep.com.br/ws"
    })
    api.get(`/${cepConvert}/json`)
      .then(({data}) =>{
        if ("erro" in data){
          alert("Não encontramos o endereço referente ao CEP.")
          setLoad(false)
        }else{
          if(event.target.id === 'comercial'){
            setAddressCommercial({
              logradouro: data.logradouro,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf
            })
          }else{
            setAddress({
              logradouro: data.logradouro,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf
            })
          }
        }
        setLoad(false)
       })
       .catch(err => {
         alert("Digite o CEP corretamente.")
         setLoad(false)
        })
  }
  return (
    <div className="step-1">
      <div className="step-header">
        <img src={Spy} alt="Espiao" />
        <h4 className="mb-0 ml-5">Informações do Investigado(a)</h4>
      </div>
      
      <div className="form-row w-100">
        <div className="col-md-6">
          <Input 
            name="form_nome_investigado" 
            label="Nome completo" 
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_profissao_investigado" 
            label="Profissão"
          />
        </div>
        <div className="col-md-2">
          <InputMask 
            name="form_cpf_investigado" 
            label="CPF (opcional)" 
            mask="999.999.999-99"
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_rg_investigado" 
            label="RG (opcional)"
          />
        </div>
      </div>

      
      <div className="form-row" style={{width: "100%"}}>
        <div className="col-md-2">
          <InputMask 
            name="form_cep_investigado" 
            label="CEP*"
            mask="99.999-999"
            onBlur={handleCep}
          />
        </div>
        <div className="col-md-7">
          <Input 
            name="form_endereco_investigado" 
            label="Endereço*" 
            defaultValue={address.logradouro} 
          />
        </div>
        <div className="col-md-1">
          <Input 
            name="form_n_investigado" 
            label="Número*" 
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_local_ref_investigado" 
            label="Complemento" 
          />
        </div>
      </div>

      <div className="form-row w-100">
        <div className="col-md-3">
          <Input 
            name="form_bairro_investigado" 
            label="Bairro*" 
            defaultValue={address.bairro}
          />
        </div>
        <div className="col-md-3">
          <Input 
            name="form_referencia_investigado" 
            label="Referência*" 
            defaultValue={address.referencia}
          />
        </div>
        <div className="col-md-2">
          <SelectContrato 
            name="form_estado_investigado" 
            label="Estado*" 
            defaultValue={address.uf}
            data={ufs}
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_cidade_investigado" 
            label="Cidade*" 
            defaultValue={address.cidade}
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_pais_investigado" 
            label="País*" 
          />
        </div>
      </div>  
      <div className="form-row w-100">
        <div className="col-md-2">
          <InputMask 
            name="form_celular_investigado" 
            label="Celular" 
            mask="(99) 99999-9999"
          />
        </div>
        <div className="col-md-2">
          <InputMask 
            name="form_telefone_outro_contato_investigado" 
            label="Outro Contato" 
            mask="(99) 9999-9999"
          />
        </div>
        <div className="col-md-8">
        </div>
        {/* <div className="col-md-2">
          <InputMask 
            name="form_telefone_comercial_investigado" 
            label="Telefone Comercial" 
            mask="(99) 9999-9999"
          />
        </div>
        <div className="col-md-6">
          <Input
            type="email" 
            name="form_email_investigado" 
            label="*E-mail"
            help="Nunca vamos compartilhar seu e-mail, com ninguém." 
        />
        </div> */}
        
        <p>Endereço Comercial:</p>
        <div className="form-row" style={{width: "100%"}}>
        <div className="col-md-2">
          <InputMask
            id="comercial"
            name="form_cep_comercial_investigado"
            label="CEP"
            mask="99.999-999"
            onBlur={handleCep}
          />
        </div>
        <div className="col-md-7">
          <Input 
            name="form_end_comercial_investigado"
            label="Endereço"
            defaultValue={addressCommercial.logradouro} 
          />
        </div>
        <div className="col-md-1">
          <Input 
            name="form_n_comercial_investigado"
            label="Número" 
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_endereco_complemento_comercial_investigado" 
            label="Complemento"
          />
        </div>
      </div>

      <div className="form-row w-100">
        <div className="col-md-3">
          <Input 
            name="form_bairro_comercial_investigado" 
            label="Bairro" 
            defaultValue={addressCommercial.bairro}
          />
        </div>
        <div className="col-md-3">
          <Input 
            name="form_referencia_comercial_investigado" 
            label="Referência" 
            defaultValue={addressCommercial.referencia}
          />
        </div>
        <div className="col-md-2">
          <SelectContrato
            name="form_uf_comercial_investigado" 
            label="Estado" 
            defaultValue={addressCommercial.uf}
            data={ufs}
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_cidade_comercial_investigado" 
            label="Cidade" 
            value={addressCommercial.cidade}
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_pais_comercial_investigado" 
            label="País" 
          />
        </div>
      </div>  
      </div>  
      {load ? <Loding /> : null}  
    </div>
  )
}

export default Step_4;