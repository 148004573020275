import React, {useState} from 'react'

import Checkbox from "../../Checkbox"
import anim from "../../../../img/contract/Agreement.svg"

import ContratoGE from "../../../../MINUTA_CONTRATUAL_BR.pdf"
import ContratoSP from "../../../../MINUTA_CONTRATUAL_SP.pdf"
import CodigoGe from "../../../../codigo_etica_ge.pdf"
import CodigoSp from "../../../../codigo_etica_sp.pdf"
import { useParams } from 'react-router-dom'

const Step_1 = () => {
  const {uf} = useParams()
  
  return (
    <div className="step-1">
      <img src={anim} alt="Anime" style={{width: "300px", marginBottom: "20px"}}/>
      <h4>Atenção, leia o Contrato antes de prosseguir</h4>
      <div className="form-row text-center">
        <div className="col-md-6">
          <a href={
            uf === 'ge'
              ? ContratoGE
              : ContratoSP
          } className="btn btn-type-2 mt-20" target="_blank">Ler o contrato</a>
        </div>
        <div className="col-md-6">
          <a href={
            uf === 'ge'
              ? CodigoGe
              : CodigoSp
          } className="btn btn-type-2 mt-20" target="_blank">Ler o Código de Ética</a>
        </div>
      </div> 
      <Checkbox 
        name="form_aceite"
        label="Li o contrato, concordo e aceito as condições"
        
        // defaultValue={checked}
        
        // help="É preciso a confirmação para proceguir."
      />
      
    </div>
  )
}

export default Step_1;