import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Medalha from "../img/app/medalha.webp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import HandleMask from '../context/HandleMask'

const Mainslider = ({locale}) => {
  const {uf} = useParams()
  const [logoTel, setLogoTel] = useState(locale)
  const [width, setWidth] = useState(1700)
  const [showBox, setShowBox] = useState(true);

  console.log(locale)
  useEffect(()=>{

    setWidth(window.innerWidth)

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  },[])

  function handleScroll() {
    const alturaTotal = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    if (window.pageYOffset / alturaTotal > .35 ) {
      setShowBox(false);
    } else {
      setShowBox(true);
    }
  }

  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 15000,
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
 

  return (
    <div className="web">
      <div id="merox-slider-area" className="merox-slider-area slider-area-bg web">
        <div className="merox-main-slider">
          <OwlCarousel className="owl-theme owl-carousel" {...options}>
            <div className="merox-single-slider">
              <div className="slid-bg-1 bg-1" />
              <div className="merox-single-table">
                <div className="merox-single-tablecell">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-10 col-md-12">
                        <div className="merox-slider-area-content">
                          <h2 className="h2-logo">
                          <img src={logoTel.logo} alt="logo" style={{width: "40vw"}}/>
                          </h2>
                          <h2>
                           <i className="fa fa-phone pr-3" ></i><b>{HandleMask(logoTel.telefone[0])}</b>
                          </h2>
                          <p>
                          Ligue e saiba mais
                          </p>
                          <div className="slider-area-btn">
                            <a href={`tel:${logoTel.telefone[0]}`} className="btn btn-type-2">
                              Ligue agora 
                              <i className="fa fa-long-arrow-right ml-2"></i>
                            </a>
                            <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/contact"}`} className="btn btn-type-3">
                              Fale conosco
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="merox-single-slider">
              <div className="slid-bg-1 bg-2" />
              <div className="merox-single-table">
                <div className="merox-single-tablecell">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-10 col-md-12">
                        <div className="merox-slider-area-content">
                          <h2>
                            <b>Grande</b> experiência em <br />casos <b>Conjugais.</b>
                          </h2>
                          <p>
                            Faça um orçamento.
                          </p>
                          <div className="slider-area-btn">
                            <a href={`tel:${logoTel.telefone}`} className="btn btn-type-2">
                            Ligue agora  
                              <i className="fa fa-long-arrow-right ml-2"></i>
                            </a>
                            <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/about"}`} className="btn btn-type-3">
                              Sobre nós
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="merox-single-slider">
              <div
                className="slid-bg-1 bg-3"
              ></div>
              <div className="merox-single-table">
                <div className="merox-single-tablecell">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-12">
                        <div className="merox-slider-area-content">
                          <h2>
                            Equipe especialista em <br />casos <b>Empresariais</b>.
                          </h2>
                          <p>
                            Orientação <b>jurídica</b>. <br /> Faça um orçamento.
                          </p>
                          <div className="slider-area-btn">
                            <a href={`tel:${logoTel.telefone}`} className="btn btn-type-2">
                            Ligue agora 
                              <i className="fa fa-long-arrow-right ml-2"></i>
                            </a>
                            <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/about"}`} className="btn btn-type-3">
                              Sobre nós
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
          {
          (uf == "sp") 
            ? <img src={Medalha} alt="img" 
              className="medalha"/> 
            : null 
          }
        </div>
      </div>
      <div>
        {/* <div className="container" >
          <div className="row">
            <div className="col-xl-12 text-center pt-4">
              <div>
                <h2 style={{
                  fontSize: 20, color: "#2D2D2D", fontWeight: 700
                  }}>
                  Atendimento em todo <b style={{color: "#9B0008", textTransform: "uppercase"}}>Brasil</b>
                </h2>                

                <a  style={{opacity: showBox ? 1 : 0, pointerEvents: showBox ? "auto" : "none"}}
                  href={`https://${window.innerWidth < 700 ? 'api' : 'web'}.whatsapp.com/send?phone=${logoTel.telefone[1].substring(1)}&text=Ol%C3%A1!%20Gostaria%20de%20entrar%20em%20contato%20com%20voc%C3%AA.`} target="_blank">
                  <div class="suspense">
                    <h1>Detetive Particular {locale.uf_conector} {locale.uf === 'ge' ? ' todo o Brasil' : locale.uf}</h1>
                    <h3>Descubra a verdade AGORA</h3>
                    <p><u>Clique aqui</u> e fale conosco imediatamente!</p>
                  </div>
                </a>                
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );

};

export default Mainslider;

