import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Formulario from './Fomulario';
import Utilidade from "../context/Unidades"
import HandleMask from '../context/HandleMask'
import BtInstragran from '../components/ButtonInstagram'

const MenuLeft = () => {
  const [contatos, setContatos] = useState([])
  const {uf} = useParams()

  useEffect(() => {
    setContatos(Utilidade.renderTelVisivel(uf))
  }, [])

  return (
    <div className="d-none d-md-block d-xl-block">
      <div className="single-sid-wdg">
        <div className="widget_recent_entries">
          <h3>Principais serviços</h3>
          <div className="menu-service fade-down">
            <div className="separet-divider"></div>
            <Link
              className="dropdown-item"
              to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_conjugal"}`}
            >
              Investigação conjugal
            </Link>
            <div className="separet-divider"></div>
            <Link
              className="dropdown-item"
              to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_filhos"}`}
            >
              Investigação de filhos
            </Link>
            <div className="separet-divider"></div>
            <Link
              className="dropdown-item"
              to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_empresarial"}`}
            >
              Investigação empresarial
            </Link>
            <div className="separet-divider"></div>
            <Link
              className="dropdown-item"
              to={`${process.env.PUBLIC_URL + "/" + uf + "/localizacao_pessoas"}`}
            >
              Encontrar pessoas
            </Link> 
            <div className="separet-divider"></div>
            <Link
              className="dropdown-item"
              to={`${process.env.PUBLIC_URL + "/" + uf + "/golpista_amoroso"}`}
            >
              Golpista Amoroso
            </Link>
            <div className="separet-divider"></div>
            <Link
              className="dropdown-item"
              to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_cuidador_idoso"}`}
            >
              Investigação de cuidador de idoso
            </Link>
          </div>
        </div>
      </div>
      <div className="single-sid-wdg sm-mt-30">
        <div className="widget_recent_entries">
          <h3>Fale Conosco</h3>
          <Formulario />
        </div>  
      </div>
      
      <div className="single-sid-wdg">
        <div className="widget_categories">
          <h3>Contatos</h3>
          <ul>
            {contatos.map(value => 
              <>
                {value.principal
                  ? <li>
                      <a href={`tel:${value.contato}`}>
                        <i className="fa fa-phone mr-3"></i>
                        <strong>{value.contato}</strong>
                      </a>
                    </li>
                  : <li>
                      <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=${value.contato}`} target="_blank">
                        <i className="fa fa-whatsapp mr-3"></i>
                        {HandleMask(value.contato)}
                      </a>
                    </li>
                }
              </>
            )}
          </ul>
        </div>
      </div>

      <div className="single-sid-wdg">
        <h3>Redes sociais</h3>
        <ul className="wdg-follow-us">
          <li>
            <a href="https://instagram.com/carmem_investigacoes?utm_medium=copy_link" target="_blank">
              <i className="fa fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MenuLeft;