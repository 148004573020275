import React, { useEffect, useState } from "react";
import Map from "./Map";
import icon1 from "../img/contact/icon-1.png";
import icon2 from "../img/contact/icon-2.png";
import icon3 from "../img/contact/icon-3.png";

import Formulario from "../components/Fomulario"
import {FaRegClock} from "react-icons/fa"
import Unidades from "../context/Unidades";
import { useParams } from "react-router";
import HandleMask from '../context/HandleMask'

const ContactContent = () => {
  const [contatos, setContatos] = useState([])
  const {uf} = useParams()

  useEffect(() => {
    setContatos(Unidades.renderContato(uf))
  },[])

  return (
    <>
      <div id="merox-contact-area" className="merox-contact-area mt-50 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
                <h4>Fale conosco</h4>
                <h3>Contato</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 pr-30">
              <div className="appnox-contact-form-area services-box">
                <Formulario />
                <div className="footer-form">
                  <FaRegClock size={30} />
                  <div>
                    <strong>HORÁRIO DE ATENDIMENTO:</strong>
                    <p>Segunda à sexta de 9:00 às 18:00 / Sábado de 9:00 às 13:00</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 footer-single-wedget-contact">
              <ul className="contact-details">
              {contatos.map(value =>
                value.telefone.map(tel => 
                  tel.principal
                    ? <li className="phone-footer">
                        <a href={`tel:${tel.contato}`}>
                        <i className="fa fa-phone"></i>
                        {tel.contato}
                        </a>
                      </li>
                    : null
                )
                )}
                {contatos.map(value => 
                  <>
                    <li>
                    {value.address === null
                      ? null
                      : <i className="fa fa-map-marker"></i>
                    }
                    {value.address}
                    <br />
                    {value.city} {value.cep === null ? null : ` - ${value.cep}`}
                    </li>
                    {value.telefone.map(whats => 
                      whats.whatsapp
                        ? <li className="phone-footer">
                            <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=${whats.contato}`} target="_blank">
                              <i className="fa fa-whatsapp"></i>
                              {HandleMask(whats.contato)}
                            </a>
                          </li>
                          : null
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ContactContent;
