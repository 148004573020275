import React from "react";
import CountUp from "react-countup";
import icon1 from "../img/counter/1.svg";
import icon2 from "../img/counter/2.svg";
import icon3 from "../img/counter/3.svg";
import icon4 from "../img/counter/4.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";

const CounterMobile = () => {

    const options = {
        loop: true,
        autoplay: true,
        smartSpeed: 1000,
        dots: false,
        nav: false,
        autoplayTimeout: 1700,
        margin: 25,
        responsive: {
            0: {
                items: 1
            }
        }
    }

    return (
        <>
            <div
                id="merox-counter-area"
                className="merox-counter-area mb-70 wow fadeInUp"
            >
                <div className="container">
                    <div className="row">

                        <OwlCarousel className="owl-carousel owl-theme" {...options}>
                            <div className="about-single-counter-mobile">
                                <img src={icon1} alt="icon" />
                                <h2 className="counter">
                                    <CountUp start={0} end={5950} duration={2.75} />
                                </h2>
                                <span>Clientes Felizes</span>
                            </div>

                            <div className="about-single-counter-mobile">
                                <img src={icon2} alt="icon" />
                                <h2 className="counter">
                                    <CountUp start={0} end={2500} duration={2.75} />
                                </h2>
                                <span>Parceiros Confiáveis</span>
                            </div>

                            <div className="about-single-counter-mobile">
                                <img src={icon3} alt="icon" />
                                <h2 className="counter">
                                    <CountUp start={0} end={6580} duration={2.75} />
                                </h2>
                                <span>Projetos Realizados</span>
                            </div>

                            <div className="about-single-counter-mobile">
                                <img src={icon4} alt="icon" />
                                <h2 className="counter">
                                    <CountUp start={0} end={36973} duration={2.75} />
                                </h2>
                                <span>Xícaras de Café</span>
                            </div>

                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CounterMobile;