import React, { useState, useEffect } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import details from "../img/services/1.jpg";


import MenuLeft from "./MenuLeft";


const Conjugais = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  return (
    <>
      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div id="menu-left" className="col-xl-4 col-lg-4">
              <MenuLeft />
            </div>
            <div className="col-xl-8 col-lg-8 pr-30">
              <div className="merox-blog-details-wraper">
                <div className="blog-details-content">
                  <div className="blog-details-img mb-4">
                    <img src={details} alt="img" />
                  </div>
                  <p> 
                    A falta de interesse por intimidade ou eventos em casal, 
                    falta de transparência com seus pertences e excesso de 
                    cuidados são elementos primordiais para procurar um <strong>detetive particular</strong> e iniciar uma 
                    <strong> Investigação Conjugal.</strong>
                    <br />
                    <br />
                    {/* A <strong>Carmem Investigações</strong> analisa as suspeitas de infidelidade 
                    com sigilo, cuidado e confiança. O objetivo é levar 
                    informações e dados referentes à vida extra conjugal do 
                    cônjuge. */}
                  </p>
                  <h4 style={{color: "#9B0008"}}>
                    Como identificar a necessidade de uma Investigação Conjugal?
                  </h4>
                  <p>
                  Os principais motivos para buscar um <strong> detetive particular</strong> e
                  iniciar a <strong>Investigação Conjugal</strong> são situações que geram 
                  desconfiança por parte do cônjuge, namorado (a) ou 
                  companheiro (a). Elas podem ser identificadas por meio da 
                  mudança repentinas de atitude em relação às decisões do casal, oscilação 
                  de humor, como irritabilidade, mudanças repentina de 
                  compromissos durante a semana, como viagens que duram mais
                  de um dia.
                  <br />
                  <br />
                    Os primeiros sinais de uma possível infidelidade são:
                    <ul className="about-content-list mb-5">
                      <li>
                        <i className="fa fa-check"></i> Alterações na rotina de trabalho
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Mudança repentina de horários
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Início de novas atividades
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Omissão nas escolhas do casal
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Vaidade exagerada
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Falar baixo em ligações telefônicas
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Proibição do cônjuge de acessar o celular
                      </li>
                    </ul>
                  </p>
                  {/* <h4 style={{color: "#9B0008"}}>A atuação do detetive particular na Investigação Conjugais</h4>

                  <p>
                    O <strong>detetive particular</strong> irá levantar provas para a comprovação
                    ou não das suspeitas. Os primeiros movimentos desta 
                    <strong> investigação</strong> são necessários para afastar as dúvidas da 
                    traição extraconjugal. Isso é extremamente importante caso 
                    haja algum futuro processo judicial
                  </p>

                  <h4 style={{color: "#9B0008"}}>As etapas da Investigação Conjugal</h4>

                  <p>
                    A <strong>Investigação Conjugal</strong> precisa ser realizada
                    com <strong>detetive particular </strong> treinado e que 
                    possua técnicas e equipamentos. 
                    Inicialmente, ele irá reunir todas as informações básicas 
                    sobre o investigado, como nome completo, local de trabalho 
                    ou estudo, horários de atividades durante a semana e 
                    características físicas de cor de cabelo e tom de pele.
                    <br />
                    <br />
                    Após a coleta dos dados iniciais e contrato assinado, a 
                    <strong> Investigação Conjugal</strong> passa pelas seguintes etapas:
                    <br />
                    <br />
                    <strong>1° Observação online;</strong> 
                    <br />
                    <br />
                    <strong>2° Acompanhamento presencial; </strong> 
                    <br />
                    <br />
                    <strong>3° Reunião dos fatos;</strong> 
                    <br />
                    <br />
                    <strong>4° Conclusão da investigação conjugal;</strong> 
                  </p> */}
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Conjugais;
