import React from "react";

const Errorcontent = () => {
  return (
    <>
      <div
        id="merox-error-area"
        className="merox-error-area text-center mt-100 mb-100"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 mx-auto">
              <div className="merox-content-box">
                <h2>404</h2>
                <h3>oops! that page can not be found.</h3>
                <p>
                  it looks like nothing was found at this location. maybe try a
                  search?
                </p>
                <div className="error-form-box">
                  <input
                    className="form-control"
                    placeholder="search"
                    name="text"
                    type="text"
                  />
                  <button className="btn btn-type-1" type="submit">
                    submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Errorcontent;
