import React from "react";
import ReactDOM from "react-dom";
import "font-awesome/css/font-awesome.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-WMWRHXM'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  // <React.StrictMode>
    <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
