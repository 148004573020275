import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import BlogList from "../components/BlogList";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";

import Bg from "../img/blog/banner.webp"

import BtWhatsapp from "../components/ButtonWhatsapp";

const Bloglist = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb 
          menu=""
          name="empresarial"
          bg={Bg}
        />
        <BlogList />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default Bloglist;
