import React, {useState} from 'react'
import Input from '../../Input';
import Car from "../../../../img/contract/car.svg"
import SelectContrato from '../../SelectContrato';


const Step_5 = () => {
  const [tipoVeiculo, setTipoVeiculo] = useState([
    {option: "Não Possui", name: "Não Possui"},
    {option: "Carro", name: "Carro"},
    {option: "Uber", name: "Uber"},
    {option: "Moto", name: "Moto"},
    {option: "Caminhão", name: "Caminhão"}
  ])

  return (
    <div className="step-1">
      <div className="step-header">
      <img src={Car} alt="Car" />
        <h4 className="mb-0 ml-5">Veículo Utilizado Pelo Investigado(a)</h4>
      </div>
      <div className="form-row w-100">
        <div className="col-md-2">
          <SelectContrato 
            name="form_tipo_veiculo_investigado" 
            label="Tipo do Veículo" 
            data={tipoVeiculo}
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_marca_veiculo_investigado" 
            label="Marca" 
          />
        </div>
        <div className="col-md-3">
          <Input 
            name="form_modelo_veiculo_investigado" 
            label="Modelo" 
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_cor_veiculo_investigado" 
            label="cor" 
          />
        </div>
        <div className="col-md-3">
          <Input 
            name="form_placa_veiculo_investigado" 
            label="Placa" 
          />
        </div>
      </div>
    </div>
  )
}

export default Step_5;