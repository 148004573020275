import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";

import Bg from "../img/services/bg5.jpg"
import RelacionamentoVirutal from "../components/RelacionamentoVirtual";

import BtWhatsapp from "../components/ButtonWhatsapp";

const RelacionamentoBreadcrumb = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb
          menu="Serviços"
          name="Relacionamento Virtual" 
          bg={Bg}
        />
        <RelacionamentoVirutal />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default RelacionamentoBreadcrumb;
