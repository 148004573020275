import React from "react";
import abimg1 from "../img/about/sobre_img.webp";
import { Link, useParams } from "react-router-dom";

const About = () => {
  const {uf} = useParams()
  return (
    <>
      <div id="merox-about-area" className="merox-about-area mt-100 mb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-12 wow fadeInLeft">
              <div className="about-img-area">
                <img src={abimg1} alt="about" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 offset-xl-1 wow fadeInRight">
              <div className="section-title">
                <h4>Quem Somos</h4>
                <h3>
                  Nossa <span>Família</span>
                </h3>
              </div>
              <div className="about-content">
                <p>
                Investigação sempre foi um traço marcante da família Carmem Investigações.
                <br />
                <br />
                Carmem e sua irmã Lúcia herdaram toda experiência no segmento. Com o passar do tempo, a empresa se consolidou no mercado. Atualmente, a Carmem atua ao lado das duas filhas e da irmã nas demandas de investigações e cursos.
                <br/>
                <br/>
                Atuando nas seguintes áreas...
                </p>
                <ul className="about-content-list mb-5">
                  <li>
                    <i className="fa fa-check"></i> <Link>Investigação Conjugal</Link>
                  </li>
                  <li>
                    <i className="fa fa-check"></i> <Link>Investigação de Filhos</Link>
                  </li>
                  <li>
                    <i className="fa fa-check"></i> <Link>Investigação Empresarial</Link>
                  </li>
                  {/* <li>
                    <i className="fa fa-check"></i> <Link>Relacionamento virtual</Link>
                  </li> */}
                  <li>
                    <i className="fa fa-check"></i> <Link>Investigação de Cuidador de Idosos</Link> 
                  </li>
                </ul>
                <Link style={{width: '200px'}} to={`${process.env.PUBLIC_URL + "/" + uf + "/about"}`} className="btn btn-type-4">
                  Sobre nós
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
