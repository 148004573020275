import React, { Component } from "react";
import {useParams } from "react-router-dom";
import MapaSP from "../img/mapas/mapaSP.jpeg"
import MapaRS from "../img/mapas/mapaRS.jpeg"
import MapaBA from "../img/mapas/mapaBA.jpeg"
import MapaMG from "../img/mapas/mapaMG.jpeg"
import MapaMT from "../img/mapas/mapaMT.jpeg"
import MapaGO from "../img/mapas/mapaGO.jpeg"
import MapaSC from "../img/mapas/mapaSC.jpeg"
import MapaRJ from "../img/mapas/mapaRJ.jpeg"
import MapaPR from "../img/mapas/mapaPR.jpeg"

import MapaBR from "../img/mapas/mapaBR.jpeg"

const ImagemMapa = () => {

  const {uf} = useParams()
  var pathImagem=MapaBR;

  console.log(uf);
  if(uf === "sp"){
    pathImagem=MapaSP;
  }else if(uf === "mg"){
    pathImagem=MapaMG;
  }else if(uf === "rs"){
    pathImagem=MapaRS;
  }else if(uf === "ba"){
    pathImagem=MapaBA;
  }else if(uf === "mt"){
    pathImagem=MapaMT;
  }else if(uf === "go"){
    pathImagem=MapaGO;
  }else if(uf === "sc"){
    pathImagem=MapaSC;
  }else if(uf === "rj"){
    pathImagem=MapaRJ;
  }else if(uf === "pr"){
    pathImagem=MapaPR;
  }

  return (
    <div className="row mapaBrasil mobile-h4" id="mapa">
        <div className="col"><img src={pathImagem}/></div>  
        
        <div className="col">
          <h4>Escritório para atendimento nas principais capitais do País . Respeito e discrição com cada caso.</h4>
          <h3>Estaremos sempre por perto quando precisar.</h3>
        </div>
    </div>
  );
};

export default ImagemMapa;
