import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import Bg from "../img/app/banner.webp"
import CursoDetetive from "../components/CursoDetetive";

import BtWhatsapp from "../components/ButtonWhatsapp";

const Curso = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb
          menu=""
          name="Curso de Detetive" 
          bg={Bg}
        />
        <CursoDetetive />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default Curso;
