import React, { useState, useEffect } from 'react';
import {FaInstagram} from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Unidades from '../context/Unidades';


const BtInstragran = () => {
  return (
    <div>
      <a href='https://www.instagram.com/carmem_investigacoes/' target="_blank">
        <FaInstagram className="icon mr-2 instagran" />
      </a>
    </div>
  )
}


export default BtInstragran;