const handleMaskTel = (tel) => {
  const mask = 
    tel.length == 13
    ? `0${tel.slice(3,6)} ${tel.slice(6, 9)} ${tel.slice(9, 13)}`
    : tel.length < 14 
      ? `${tel.slice(0,2)}(${tel.slice(2, 5)}) ${tel.slice(5, 8)}-${tel.slice(8 - 12)}`
      : `${tel.slice(3, 5)} ${tel.slice(5, 10)}-${tel.slice(9- 13)}`
  return mask
}

export default handleMaskTel;