import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Unidade from '../context/Unidades';

const PoliticaPrivacidade = () => {
    const [contatos, setContatos] = useState([])
    const { uf } = useParams()

    useEffect(() => {
        setContatos(Unidade.renderContato(uf))
    }, [])

    return (
        <>
            <div className="pt-50 pb-60">
                <div className="container">
                    <div className="row">
                        <div classname="col-xl-12">
                            <div className="section-title">
                                <h4>Data de Revisão: 21/03/2024</h4>
                                <h3>Política de Privacidade</h3>
                                <span className="wow fadeInUp mb-50">
                                    <p className="mx">Esta Política de Privacidade descreve como a empresa Carmem Investigações coleta, usa e protege as informações pessoais dos usuários do nosso site carmeminvestigacoes.com.br.</p>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title">
                                <h2>1 - Informações Coletadas</h2>
                                <h4>1.1 - Informações de Registro: </h4>
                                <p>Quando você visita nosso site, podemos coletar informações de registro padrão, incluindo seu endereço IP, tipo de navegador, páginas visitadas e horários de acesso.</p>
                                <h4>1.2 - Informações de Contato:</h4>
                                <p>Se você entrar em contato conosco através do formulário de contato ou por e-mail, podemos coletar informações como seu nome, endereço de e-mail, número de telefone e qualquer outra informação que você nos forneça.</p>
                                <h4>1.3 - Informações de Pagamento:</h4>
                                <p>Se você usar nossos serviços de investigação e pagamento, podemos coletar informações de pagamento, como detalhes do cartão de crédito ou outras informações de faturamento.</p>
                                <h4>1.4 - Informações Adicionais:</h4>
                                <p>Podemos coletar outras informações relevantes para os serviços que fornecemos, como informações sobre o caso de investigação em questão.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title">
                                <h2>2 - Uso das Informações</h2>
                                <h4>2.1 - As informações coletadas podem ser usadas para os seguintes propósitos:</h4>
                                <ul className="privacy-list">
                                    <li>
                                        <i className="fa fa-check"></i>
                                        Para fornecer e manter nossos serviços de investigação;
                                    </li>
                                    <li>
                                        <i className="fa fa-check"></i>
                                        Para responder às suas consultas e solicitações;
                                    </li>
                                    <li>
                                        <i className="fa fa-check"></i>
                                        Para processar pagamentos e fornecer faturamento adequado;
                                    </li>
                                    <li>
                                        <i className="fa fa-check"></i>
                                        Para personalizar sua experiência com nossos serviços;
                                    </li>
                                    <li>
                                        <i className="fa fa-check"></i>
                                        Para cumprir com obrigações legais.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title">
                                <h2>3 - Compartilhamento de Informações</h2>
                                <h4>3.1 - Compartilhamento com Terceiros</h4>
                                <p>Nós não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para cumprir com obrigações legais ou quando você nos conceder consentimento para fazê-lo.</p>
                                <h4>3.2 - Compartilhamento com Autoridades</h4>
                                <p>Em casos de investigações específicas, podemos compartilhar informações relevantes com autoridades policiais ou outras partes envolvidas diretamente na investigação.</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h2>4 - Segurança</h2>
                                    <h4>4.1 - Medidas de Segurança</h4>
                                    <p>Implementamos medidas de segurança adequadas para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h2>5 - Consentimento</h2>
                                    <h4>5.1 - Termos de Privacidade</h4>
                                    <p>Ao usar nossos serviços ou visitar nosso site, você consente com os termos desta Política de Privacidade.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h2>6 - Alterações nesta Política de Privacidade</h2>
                                    <h4>6.1 - Atualização</h4>
                                    <p>Podemos atualizar esta Política de Privacidade periodicamente. Quaisquer alterações serão publicadas nesta página, e a data de revisão será atualizada na parte superior da página. É sua responsabilidade revisar esta Política de Privacidade periodicamente para ficar informado sobre as práticas de privacidade da nossa empresa.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h2>7 - Contato</h2>
                                    <h4>7.1 - Formulário</h4>
                                    <p>Se você tiver dúvidas sobre esta Política de Privacidade ou sobre as práticas de privacidade da nossa empresa, entre em contato conosco através dessa
                                        <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/contact"}`}> página</Link>
                                        .</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PoliticaPrivacidade