import React from 'react'
import SendMail from "../../../../img/contract/send_mail.svg"

const Step_7 = (form_data) => {
  

  return (
    <div className="step-1">
      <img src={SendMail} alt="Personal"  style={{width: "300px", marginBottom: "20px"}}/>
      <h4>Clique em enviar para concluir o contrato</h4>
      <span>Você vai receber uma cópia no e-mail.</span>
    </div>
  ) 
}

export default Step_7;