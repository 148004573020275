
const ReclameAqui = ({data_model}) => {
console.log("Teste reclame aqui")
return (
    <div id="ra-verified-seal" style={{position:"fixed", top:"10px", right:"10px"}}> 
        <script type="text/javascript" 
            id="ra-embed-verified-seal" 
            src="https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js" 
            data-id="SjR0cEhYd05Rc3N3UV9rczpjYXJtZW0taW52ZXN0aWdhY2Fv" 
            data-target="ra-verified-seal" 
            data-model="1">
        </script>
    </div>)

}

export default ReclameAqui;