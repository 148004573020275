import React from "react";

import { useParams } from "react-router-dom";

import Utilidade from "../context/Unidades"
import handleMaskTel from '../context/HandleMask'
import ImagemMapa from "../components/ImgMapa";

const Telefone = () => {

  const { uf } = useParams()

  return (
    <>
      <div
        id="merox-team-area"
        className="merox-team-area mt-50 mb-0 wow fadeInUp phone"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4">
              <div className="section-title tel">
                <h4>Ligações gratuitas</h4>
                <h3 className="ml-5">
                  <a href={`tel:${Utilidade.renderLogoFone(uf).telefone[0]}`} style={{ textDecoration: 'none' }}>
                    <i className="fa fa-phone" style={{ color: "#9B0008" }}></i> {handleMaskTel(Utilidade.renderLogoFone(uf).telefone[0])}
                  </a>
                  <br />
                  {Utilidade.renderLogoFone(uf).telefone.length > 1
                    ? <a href={`tel:${Utilidade.renderLogoFone(uf).telefone[1]}`} style={{ textDecoration: 'none' }}>
                      <i className="fa fa-phone" style={{ color: "#9B0008" }}></i> {handleMaskTel(Utilidade.renderLogoFone(uf).telefone[1])}
                    </a>
                    : null
                  }

                  <br />
                </h3 >
                <p className="ml-5">Ligação fixo e celular</p>
              </div>
            </div>
            <div className="col-xl-8">
              <ImagemMapa />
            </div>
          </div>
        </div>
        <div className="waves-container">
          <div class="waves-1">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Telefone;
