import React, { useEffect, useState } from "react";
import details from "../img/services/7.jpg";


import MenuLeft from "./MenuLeft";


const CuidadorIdoso = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div id="menu-left" className="col-xl-4 col-lg-4">
              <MenuLeft />
            </div>
            <div className="col-xl-8 col-lg-8 pr-30">
              <div className="merox-blog-details-wraper">
                <div className="blog-details-content">
                  <div className="blog-details-img mb-4">
                    <img src={details} alt="img" />
                  </div>
                  <p> 
                    Atualmente, 13% da população brasileira é composta por pessoas com mais de 65 anos. 
                    O dado da Organização Mundial da Saúde (OMS), mostra que o <strong>idoso</strong> vem ocupando 
                    mais espaço entre a população ativa no país.
                    Porém, em muitas casas, as pessoas correspondentes a essa faixa etária necessitam da 
                    ajuda de um <strong>cuidador</strong>.
                    <br />
                    <br />
                    O crescimento por <strong>cuidador de idosos</strong> faz com que o número de denúncias de 
                    violência e de maus tratos contra o <strong>idoso</strong> cresça em 59% em todo o país. 
                    Os dados são referentes aos meses de maio a junho de 2020 segundo o Ministério da Mulher, 
                    Família e Direitos Humanos.
                  </p>
                  <h4 style={{color: "#9B0008"}}>
                    Os índices de abuso do cuidador de idosos
                  </h4>
                  <p>
                    Para saber se há algum tipo de abuso ou irregularidade no serviço de <strong>cuidador de idosos</strong> é preciso ficar atento as seguintes informações:
                    <ul className="about-content-list mb-5">
                      <li>
                        <i className="fa fa-check"></i> Exploração financeira
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Negligência com cuidados básicos
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Danos físicos e emocionais
                      </li>
                    </ul>
                    Após a verificação desses fatores, é necessário contratar um <strong>detetive particular</strong>, 
                    para iniciar a <strong>Investigação de cuidador de idosos</strong>. 
                    O objetivo principal será garantir a segurança de pais e/ou avós e permitir que recebam atendimento qualificado.
                  </p>  
                  
                  {/* <h4 style={{color: "#9B0008"}}>Passo a passo da Investigação de cuidador de idoso</h4>

                  <p>
                    O <strong>detetive particular</strong> irá investigar a trajetória do <strong>cuidador de idoso</strong> para saber se o profissional já possui alguma ocorrência por maus tratos. Além disso, irá acompanhar o dia a dia, os hábitos e o comportamento do <strong>cuidador</strong> e do <strong>idoso</strong>.
                    <br />
                    <br />
                  </p>
                  
                  <h4 style={{color: "#9B0008"}}>A Investigação de cuidador de idoso na Carmem Investigações</h4>

                  <p>
                    Com profissionais preparados e capacitados, iremos monitorar ações estranhas do <strong>cuidador de idoso</strong>, imagens, fotos e analisar qualquer suspeita de agressão ou maus tratos. Nosso objetivo é trazer tranquilidade a família e melhorar o bem-estar do <strong>idoso</strong>.
                  </p> */}
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CuidadorIdoso;
