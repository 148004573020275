import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Counter from "../components/Counter";
import About2 from "../components/About2";
import Header from "../components/Header";
import Bg from "../img/about/banner.webp"

import BtWhatsapp from "../components/ButtonWhatsapp";

const About = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb
          menu=""
          name="Sobre nós" 
          bg={Bg}
        />
        <About2 />
        <Counter />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default About;
