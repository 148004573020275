import React, {useState, useRef, useEffect} from "react"
import './styles.css'

import {Form} from '@unform/web'
import { useHistory, useParams } from 'react-router-dom';

import * as Yup from 'yup';
import Step_1 from "./Steps/Step1";
import Step_2 from "./Steps/Step2";
import Step_3 from "./Steps/Step3";
import Step_4 from "./Steps/Step4";
import Step_5 from "./Steps/Step5";
import Step_6 from "./Steps/Step6";
import Step_7 from "./Steps/Step7";

import emailjs from 'emailjs-com'
import Loding from '../../Loding/loading';

import ContratoGE from "../../../MINUTA_CONTRATUAL_BR.pdf"
import ContratoSP from "../../../MINUTA_CONTRATUAL_SP.pdf"
import Utilidade from "../../../context/Unidades"

const date = new Date()

const FormWizard = () => {
  const formRef = useRef(null);
  const history = useHistory();
  const [formulario, setFormulario] = useState([])
  const [steps, setSteps] = useState([
    { key: 'aceite', label: 'Contrato ', isDone: true, component: <Step_1 />, index: 0 },
    { key: 'contratante', label: 'Contratante', isDone: false, component: <Step_2/>, index: 1 },
    { key: 'forma-pagamento', label: 'Pagamento', isDone: false, component: <Step_3/>, index: 2 },
    { key: 'investigado', label: 'Investigado(a)', isDone: false, component: <Step_4/>, index: 3 },
    { key: 'veiculo', label: 'Veículo', isDone: false, component: <Step_5/>, index: 4 },
    { key: 'caracteristica', label: 'Características', isDone: false, component: <Step_6/>, index: 5 },
    { key: 'finalizar', label: 'Finalizar', isDone: false, component: <Step_7/>, index: 6 },
  ]);
 
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [load, setLoad] = useState(false)
  const [brand, setBrand] = useState({})
  const {uf} = useParams()

  const event = ((date.getDate() )) + "/" + ((date.getMonth() + 1)) + "/" + date.getFullYear()

  useEffect(() => {
    setBrand(Utilidade.renderRegiao(uf))
  }, [])

  function handleRandom() {
    const n1 = Math.floor(Math.random() * 1000000000 + 1)
    const n2 = Math.floor(Math.random() * 100 + 1)
    return `${n1}-${n2}`
  }

  const handleNext = async() => {
    
    if (steps[steps.length - 1].key === activeStep.key) {
      setLoad(true)

      const email = {
        form_from: uf === 'ge' ? 'carmeminvestigacoesbr@gmail.com' : 'carmeminvestigacoes@gmail.com',
        // form_from: 'ichihara7l@gmail.com',
        form_n_contrato: handleRandom(),
        form_data: event,
        form_agente: formulario[1].form_agente,
        form_servico_contratado: formulario[1].form_servico_contratado,
        form_tipo: formulario[1].form_tipo,
        form_nome: formulario[1].form_nome,
        form_rg: formulario[1].form_rg,
        form_cpf: formulario[1].form_cpf,
        form_cel: formulario[1].form_cel,
        form_tel_residencial: formulario[1].form_tel_residencial,
        form_tel_comercial: formulario[1].form_tel_comercial,
        form_email: formulario[1].form_email,
        form_profissao: formulario[1].form_profissao,
        form_end_residencial: formulario[1].form_end_residencial,
        form_n_residencial: formulario[1].form_n_residencial,
        form_endereco_complemento: formulario[1].form_endereco_complemento,
        form_bairro_res: formulario[1].form_bairro_residencial,
        form_referencia_res: formulario[1].form_referencia_residencial,
        form_cidade_residencial: formulario[1].form_cidade_residencial,
        form_estado_residencial: formulario[1].form_uf_residencial,
        form_pais_residencial: formulario[1].form_pais_residencial,
        form_cep_res: formulario[1].form_cep_residencial,
        form_cep_comercial: formulario[1].form_cep_comercial,
        form_end_comercial: formulario[1].form_end_comercial,
        form_n_comercial: formulario[1].form_n_comercial,
        form_endereco_complemento_comercial: formulario[1].form_endereco_complemento_comercial,
        form_bairro_comercial: formulario[1].form_bairro_comercial,
        form_referencia_comercial: formulario[1].form_referencia_comercial,
        form_uf_comercial: formulario[1].form_uf_comercial,
        form_cidade_comercial: formulario[1].form_cidade_comercial,
        form_pais_comercial: formulario[1].form_pais_comercial,
        form_pagamento: formulario[2].form_pagamento,
        form_vlr_total: formulario[2].form_vlr_total,
        // form_vlr_total_ext: formulario[0].form_,
        form_vlr_parcelado: formulario[2].form_vlr_parcelado,
        // form_vlr_ext: formulario[0].form_,
        form_vlr_seg_parcelado: formulario[2].form_vlr_seg_parcelado,
        // form_vlr_2_ext: formulario[2].form_vlr_seg_parcelado,
        form_prazo: formulario[2].form_prazo,
        form_nome_investigado: formulario[3].form_nome_investigado,
        form_profissao_investigado: formulario[3].form_profissao_investigado,
        form_cpf_investigado: formulario[3].form_cpf_investigado,
        form_rg_investigado: formulario[3].form_rg_investigado,
        form_endereco_investigado: formulario[3].form_endereco_investigado,
        form_n_investigado: formulario[3].form_n_investigado,
        form_local_ref_investigado: formulario[3].form_local_ref_investigado,
        form_bairro_investigado: formulario[3].form_bairro_investigado,
        form_cidade_investigado: formulario[3].form_cidade_investigado,
        form_estado_investigado: formulario[3].form_estado_investigado,
        form_pais_investigado: formulario[3].form_pais_investigado,
        form_cep_investigado: formulario[3].form_cep_investigado,
        form_telefone_outro_contato_investigado: formulario[3].form_telefone_outro_contato_investigado,
        form_telefone_comercial_investigado: formulario[3].form_telefone_comercial_investigado,
        form_celular_investigado: formulario[3].form_celular_investigado,
        form_email_investigado: formulario[3].form_email_investigado,
        form_cep_comercial_investigado: formulario[3].form_cep_comercial_investigado,
        form_end_comercial_investigado: formulario[3].form_end_comercial_investigado,
        form_n_comercial_investigado: formulario[3].form_n_comercial_investigado,
        form_endereco_complemento_comercial_investigado: formulario[3].form_endereco_complemento_comercial_investigado,
        form_bairro_comercial_investigado: formulario[3].form_bairro_comercial_investigado,
        form_referencia_comercial_investigado: formulario[3].form_referencia_comercial_investigado,
        form_uf_comercial_investigado: formulario[3].form_uf_comercial_investigado,
        form_cidade_comercial_investigado: formulario[3].form_cidade_comercial_investigado,
        form_pais_comercial_investigado: formulario[3].form_pais_comercial_investigado,
        // form_cep_comercial_investigado: formulario[3].form_cep_comercila_investigado,
        // form_endereco_comercial_investigado: formulario[3].form_endereco_comercial_investigado,
        form_tipo_veiculo_investigado: formulario[4].form_tipo_veiculo_investigado,
        form_modelo_veiculo_investigado: formulario[4].form_modelo_veiculo_investigado,
        form_marca_veiculo_investigado: formulario[4].form_marca_veiculo_investigado,
        form_cor_veiculo_investigado: formulario[4].form_cor_veiculo_investigado,
        form_placa_veiculo_investigado: formulario[4].form_placa_veiculo_investigado,
        form_infor_caracteristica_investigado: formulario[5].form_infor_caracteristica_investigado,
        form_locais_suspeitas_frequentados_investigado: formulario[5].form_locais_suspeitas_frequentados_investigado,
        form_pessoas_suspeitas_frequentados_investigado: formulario[5].form_pessoas_suspeitas_frequentados_investigado,
        form_objetivo_investigacao_investigado: formulario[5].form_objetivo_investigacao_investigado,
        form_relate_resumidamente__investigado: formulario[5].form_relate_resumidamente__investigado,
        form_aceite: formulario[0].form_aceite ? "Aceito" : "Não aceito",
        // form_contrato: `https://carmeminvestigacoes.com.br/${uf === 'ge' ? ContratoGE : ContratoSP}`,
        // form_contrato: uf === 'ge' ? 'https://carmeminvestigacoes.com.br/static/media/MINUTA_CONTRATUAL_BR.b790a883.pdf' : 'https://carmeminvestigacoes.com.br/static/media/MINUTA_CONTRATUAL_SP.184a3d40.pdf',
        form_contrato_br: uf === 'ge' ? 'block' : 'none',
        form_contrato_sp: uf != 'ge' ? 'block' : 'none',
      }
      const sendMail = await emailjs.send(
        'service_csvctcv', 'template_54hma3t',
        email,
        'user_O4fwL6B75x4ac4xqdq3D1')
        setLoad(false)

        alert('Seu contrato foi enviado com sucesso.\n\nEstamos felizes com início de nossa parceria. Você receberá em seu e-mail uma cópia do contrato,ok!');
        history.push(`/#${uf}`)
      return;
    }
   
    const index = steps.findIndex(x => x.key === activeStep.key);
    setSteps(prevStep => prevStep.map(x => {
      if (x.key === activeStep.key) x.isDone = true;
      return x;
    }))
    setActiveStep(steps[index + 1]);

  }
 
  const handleBack = () => {
    const index = steps.findIndex(x => x.key === activeStep.key);
    if (index === 0) return;
   
    setSteps(prevStep => prevStep.map(x => {
      if (x.key === activeStep.key) x.isDone = false;
      return x;
    }))
    setActiveStep(steps[index - 1]);
  }
  
  const handleSubmit = async (data, {reset}) => {
    
    try{

      if (activeStep.key === "aceite"){
        const schema = Yup.object().shape({
          form_aceite: Yup.string().required("*Você deve concordar, antes de continuar.")
        })
        await schema.validate(data, {
          abortEarly: false
        })
      }
      
      if (activeStep.key === "contratante"){
        const schema = Yup.object().shape({
          form_agente: Yup.string().required(" "),
          form_bairro_residencial: Yup.string().required(" "),
          form_referencia_residencial: Yup.string().required(" "),
          form_cidade_residencial: Yup.string().required(" "),
          form_cpf: Yup.string().required(" "),
          form_email: Yup.string().required(" "),
          form_end_residencial: Yup.string().required(" "),
          form_n_residencial: Yup.string().required(" "),
          form_nome: Yup.string().required(" "),
          form_pais_residencial: Yup.string().required(" "),
          form_rg: Yup.string().required(" "),
          form_servico_contratado: Yup.string().required(" "),
          form_uf_residencial: Yup.string().required(" "),
          form_tipo: Yup.string().required("")
        })
        await schema.validate(data, {          
          abortEarly: false
        })
      }
      
      if (activeStep.key === "forma-pagamento"){
        const schema = Yup.object().shape({
          form_pagamento: Yup.string().required(" "),
          form_vlr_total: Yup.string().required(" "),
          form_prazo: Yup.string().required(" ")
        })
        await schema.validate(data, {
          abortEarly: false
        })
      }

      if (activeStep.key === "investigado"){
        const schema = Yup.object().shape({
          form_bairro_investigado: Yup.string().required(" "),
          form_cidade_investigado: Yup.string().required(" "),
          // form_cpf_investigado: Yup.string().required(" "),
          form_endereco_investigado: Yup.string().required(" "),
          form_n_investigado: Yup.string().required(" "),
          form_nome_investigado: Yup.string().required(" "),
          form_pais_investigado: Yup.string().required(" "),
          // form_rg_investigado: Yup.string().required(" "),
          form_estado_investigado: Yup.string().required(" "),
        })
        await schema.validate(data, {
          abortEarly: false
        })
      }

      setFormulario(item => {
        item.splice(activeStep.index, 1, data)
        return [...item]
      })

      handleNext()
    }catch(err){
      if (err instanceof Yup.ValidationError){
        const erroMessages = {}

        err.inner.forEach(error => {
          erroMessages[error.path] = error.message;
        })
        formRef.current?.setErrors(erroMessages)
      }
      
    }
  }
  return (
    <div className="container contract">
      <div className="contract-img">
        <img src={brand.logo} alt="Anime" />
        <h4 className="mb-0 text center">CONTRATO DE PRESTAÇÃO DE SERVIÇOS</h4>
      </div>
      <div className="box">
        <div className="steps mb-1">
          <ul className="nav">
            {steps.map((step, i) => {
              return <li key={i} className={`${activeStep.key === step.key ? 'active' : ''} ${step.isDone ? 'done' : ''}`}>
                <div>Passo {i + 1}<br /><span>{step.label}</span></div>
              </li>
            })}
          </ul>
        </div>
        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          initialData={formulario[activeStep.index]}
        >
          <div className="step-component">
              {activeStep.component}
          </div>
        <div className="btn-component">
          <input type="button" className="btn btn-danger mr-2" value="Voltar" onClick={handleBack} disabled={steps[0].key === activeStep.key} />
          <input type="submit" 
            className="btn ml-2" 
            style={{backgroundColor:'#28a745', color:'#fff'}}
            value={steps[steps.length - 1].key !== activeStep.key ? 'Proximo' : 'Enviar'} />
        </div>
        </Form>
      </div>
      {load ? <Loding /> : null}  
    </div>
  )
}

export default FormWizard;