import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";
import React, {useEffect} from "react";
import About from "./pages/About";
import ListIps from "./pages/ListIps";
import Error from "./pages/Error";
import Empresariallist from "./pages/Empresariallist";
import Contact from "./pages/Contact";
import Home from "./pages/Home";

import ConjugaisBreadcrumb from "./pages/Conjugais"
import FilhosBreadcrumb from "./pages/Filhos";
import EmpresarialBreadcrumb from "./pages/Empresarial";
// import EmpresarialBreadcrumb2 from "./pages/Empresarial_2";
import PessoasBreadcrumb from "./pages/Pessoas";
import RelacionamentoBreadcrumb from "./pages/Relacionamento";
import IdosoBreadcrumb from "./pages/Idoso";
import GolpistaAmoroso from "./pages/GolpistaAmoroso";
import Curso from "./pages/Curso";
import FormWizard from "./components/Form/Wizard";
import Privacidade from "./pages/PrivacyPolicy";
import { useHistory, useParams } from "react-router";

function App() {

  return (
    <HashRouter hashType="noslash">
      <Switch>
        <Route
          exact
          path={["/", "/:uf"]}
          component={Home}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/listIPs"}`}
          component={ListIps}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/investigacao_conjugal"}`}
          component={ConjugaisBreadcrumb}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/investigacao_filhos"}`}
          component={FilhosBreadcrumb}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/investigacao_empresarial"}`}
          component={EmpresarialBreadcrumb}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/localizacao_pessoas"}`}
          component={PessoasBreadcrumb}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/golpista_amoroso"}`}
          component={GolpistaAmoroso}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/relacionamento_virtual"}`}
          component={RelacionamentoBreadcrumb}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/investigacao_cuidador_idoso"}`}
          component={IdosoBreadcrumb}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/about"}`}
          component={About}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/empresarial"}`}
          component={Empresariallist}
        />
        {/*<Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/blog/1"}`}
          component={BlogDetails_1}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/blog/2"}`}
          component={BlogDetails_2}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/blog/3"}`}
          component={BlogDetails_3}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/blog/4"}`}
          component={BlogDetails_4}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/blog/5"}`}
          component={BlogDetails_5}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/blog/6"}`}
          component={BlogDetails_6}
        /> */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/curso"}`}
          component={Curso}
        />
       
       
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/contrato"}`}
          component={FormWizard}
        />
        
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/contact"}`}
          component={Contact}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf/politica_privacidade"}`}
          component={Privacidade}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/error"}`}
          component={Error}
        />
      </Switch>
    </HashRouter>
  );
}

export default App;
