import AC from '../img/logo/logo_AC.svg'
import AL from '../img/logo/logo_AL.svg'
import AM from '../img/logo/logo_AM.svg'
import AP from '../img/logo/logo_AP.svg'
import BA from '../img/logo/logo_BA.svg'
import CE from '../img/logo/logo_CE.svg'
import DF from '../img/logo/logo_DF.svg'
import ES from '../img/logo/logo_ES.svg'
import GO from '../img/logo/logo_GO.svg'
import MA from '../img/logo/logo_MA.svg'
import MG from '../img/logo/logo_MG.svg'
import MS from '../img/logo/logo_MS.svg'
import MT from '../img/logo/logo_MT.svg'
import PA from '../img/logo/logo_PA.svg'
import PB from '../img/logo/logo_PB.svg'
import PE from '../img/logo/logo_PE.svg'
import PI from '../img/logo/logo_PI.svg'
import PR from '../img/logo/logo_PR.svg'
import RJ from '../img/logo/logo_RJ.svg'
import RN from '../img/logo/logo_RN.svg'
import RO from '../img/logo/logo_RO.svg'
import RR from '../img/logo/logo_RR.svg'
import RS from '../img/logo/logo_RS.svg'
import SC from '../img/logo/logo_SC.svg'
import SE from '../img/logo/logo_SE.svg'
import SP from '../img/logo/logo_SP.svg'
import TO from '../img/logo/logo_TO.svg'
import GE from '../img/carmem.svg'

const atendimentos =
  [
    // {
    //   generic: true, uf: 'SP2', uf_name: 'São Paulo', logo: SP, conector: 'em', endereco: [
    //     {
    //       address: "Av. Engenheiro Luiz Carlos Berrini, 1140 - Cidade Monções - Zona Sul - São Paulo/SP", cep: null, city: "", 
    //       telefone: [
    //         { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
    //         { contato: '+5511971679862', principal: false, whatsapp: true, isVisibilty: true },
    //       ]
    //     },
    //   ]
    // },
    {
      generic: true, uf: 'SP', uf_name: 'São Paulo', logo: SP, conector: 'em', endereco: [
        {
          address: "Av. Engenheiro Luiz Carlos Berrini, 1140 - Cidade Monções - Zona Sul - São Paulo/SP", cep: null, city: "", 
          telefone: [
            { contato: '+558000000163', principal: true, whatsapp: true, isVisibilty: true },
            // { contato: '+5511975775395', principal: true, whatsapp: true, isVisibilty: false },
          ]
        },
      ]
    },
    {
      generic: true, uf: 'BA', uf_name: 'Bahia', logo: BA, conector: 'na', endereco: [
        {
          address: "Avenida Tancredo Neves, 620 ", cep: '41820-020', city: "Salvador / BA", telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
            { contato: '+5571999419252', principal: false, whatsapp: true, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'RO', uf_name: 'Rondônia', logo: RO, conector: 'em', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
            
          ]
        }
      ]
    },
    {
      uf: 'AC', uf_name: 'Acre', logo: AC, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'PA', uf_name: 'Pará', logo: PA, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'RR', uf_name: 'Roraima', logo: RR, conector: 'em', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'AM', uf_name: 'Amazonas', logo: AM, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'AP', uf_name: 'Amapá', logo: AP, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      generic: true, uf: 'MT', uf_name: 'Mato Grosso', logo: MT, conector: 'no', endereco: [
        {
          address: 'Avenida Fernando Corrêa da Costa, 567 - Areão', cep: '78088-800', city: 'Cuiabá / MT', telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
            { contato: '+5565996854929', principal: false, whatsapp: true, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'TO', uf_name: 'Tocantins', logo: TO, conector: 'em', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'MA', uf_name: 'Maranhão', logo: MA, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'SE', uf_name: 'Sergipe', logo: SE, conector: 'em', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'PI', uf_name: 'Piauí', logo: PI, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'CE', uf_name: 'Ceará', logo: CE, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'AL', uf_name: 'Alagoas', logo: AL, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'PB', uf_name: 'Paraíba', logo: PB, conector: 'na', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'PE', uf_name: 'Pernambuco', logo: PE, conector: 'em', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: false, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'RN', uf_name: 'Rio Grande do Norte', logo: RN, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: false, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      generic: true, uf: 'RS', uf_name: 'Rio Grande do Sul', logo: RS, conector: 'no', endereco: [
        {
          address: 'Avenida Carlos Gomes, 700', cep: '90480-000', city: 'Porto Alegre / RS', telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
            { contato: '+5551996315328', principal: false, whatsapp: true, isVisibilty: true },
          ]
        }
      ]
    },
    {
      generic: true, uf: 'PR', uf_name: 'Paraná', logo: PR, conector: 'no', endereco: [
        {
          address: 'Rua Comendador Araújo, 499 - Centro', cep: "80420-000", city: 'Curitiba / PR', telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
            { contato: '+5541992132899', principal: false, whatsapp: true, isVisibilty: true },
          ]
        }
      ]
    },
    {
      generic: true, uf: 'SC', uf_name: 'Santa Catarina', logo: SC, conector: 'em', endereco: [
        {
          address: 'Avenida Rio Branco, 404 - Centro', cep: '88015-200', city: 'Florianópolis / SC', telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
            { contato: '+5548991569380', principal: false, whatsapp: true, isVisibilty: true },
          ]
        }
      ]
    },
    {
      generic: true, uf: 'MG', uf_name: 'Minas Gerais', logo: MG, conector: 'em', endereco: [
        {
          address: 'Rua Alberto Cintra, 35', cep: '31160-370', city: 'Belo Horizonte / MG', telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
            { contato: '+5531971980736', principal: false, whatsapp: true, isVisibilty: true },
          ]
        }
      ]
    },
    {
      generic: true, uf: 'RJ', uf_name: 'Rio de Janeiro', logo: RJ, conector: 'no', endereco: [
        {
          address: 'Praia de Botafogo, 228, Ala A - Botafogo', cep: '22250-040', city: 'Rio de Janeiro / RJ', telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
            { contato: '+5521998798970', principal: false, whatsapp: true, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'ES', uf_name: 'Espírito Santo', logo: ES, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'MS', uf_name: 'Mato Grosso do Sul', logo: MS, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
    {
      generic: true, uf: 'GO', uf_name: 'Goiás', logo: GO, conector: 'em', endereco: [
        {
          address: "Avenida D, esquina com rua 9, 419", cep: "74150-040", city: "Goiânia / GO", telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
            { contato: '+5562998646156', principal: false, whatsapp: true, isVisibilty: true },
          ]
        }
      ]
    },
    {
      uf: 'DF', uf_name: 'Distrito Federal', logo: DF, conector: 'no', endereco: [
        {
          address: null, cep: null, city: null, telefone: [
            { contato: '+558000000163', principal: true, whatsapp: false, isVisibilty: true },
          ]
        }
      ]
    },
  ]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  renderRegiao(uf) {
    if(uf === 'ge') {
      return {logo: GE}
    }
    if (!!uf) {
      const logo = atendimentos.filter(value => value.uf === uf.toUpperCase())
      return logo[0]
    }
  },

  renderContato(uf) {
    if(uf === 'ge'){
      const contato = atendimentos.filter(value => value.generic)
      const address = contato.map((value, index) =>{
        if (index > 0) value.endereco[0].telefone.map(tel => tel.principal = false)
        return value.endereco[0]
      })
  
      return address
    }

    if (!!uf) {
      const logo = atendimentos.filter(value => value.uf === uf.toUpperCase())
      return logo[0].endereco
    }
  },

  renderLogoFone(uf) {
    if(uf === 'ge'){
      return { logo: GE, uf: "ge", telefone: ["+558000000163"]}
    }
    if (!!uf) {
      const logo = atendimentos.filter(value => value.uf === uf.toUpperCase())
      const telefone = logo[0].endereco.map(value => {
        const tel = value.telefone.filter(res => res.isVisibilty)
        return tel.map(pr => pr.contato)
      })
      console.log('telefone:>>>> ', telefone)
      return { logo: logo[0].logo, uf: logo[0].uf_name, telefone: telefone[0], uf_code: logo[0].uf, uf_conector : logo[0].conector}
    }
  },

  renderTelVisivel(uf) {
    if (uf === 'ge') {
      return [
        {principal: true, contato: "+558000000163", whatsapp: true},
        // {principal: false, contato: "+5531971980736", whatsapp: true},
      ]
    }

    if (!!uf) {
      const logo = atendimentos.filter(value => value.uf === uf.toUpperCase())
      const telefone = logo[0].endereco.map(value =>
        value.telefone.filter(res => res.isVisibilty)
      )
      return telefone[0]
    }
  }
}