import React, { useRef, useEffect } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';

export default function InputMask({ name, label, help, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  return (
    <div className="form-group">
      <label for={fieldName}>{label}</label>
        <ReactInputMask
          className={error ? 'form-control is-invalid': 'form-control'} 
          id={fieldName}
          ref={inputRef} 
          defaultValue={defaultValue} 
          
          {...rest} />
        {!!help
          ? error ? null : <small class="form-text text-warning">{help}</small>
          : null
        }  
        {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
};