import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

const SelectContratoPG = ({ name, label, data, help, ...rest }) => {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  const [option, setOption] = useState(data)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: selectRef.current,
    })
  }, [fieldName, registerField])

  return (
    <>
    <div class="form-group">
      <label for={fieldName}>{label}</label>
      <select
        id={fieldName}
        ref={selectRef}
        defaultValue={defaultValue}
        className={error ? 'has-error form-control' : 'form-control'}
        {...rest}
      >
        <option value="0">Selecione...</option>
        {option.map(value => 
          <option value={value.option}>{value.name}</option>
          )
        }
      </select>
      {!!help
        ? error ? null : <small class="form-text text-warning">{help}</small>
        : null
      }
      {error && <span className="NameError">{error}</span>}
    </div>
    </>
  )
}
export default SelectContratoPG;