import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";

import Bg from "../img/services/bg6.jpg";
import CuidadorIdoso from "../components/CuidadorIdoso";

import BtWhatsapp from "../components/ButtonWhatsapp";

const IdosoBreadcrumb = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb
          menu="Serviços"
          name="Investigação de Cuidador de Idosos" 
          bg={Bg}
        />
        <CuidadorIdoso />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default IdosoBreadcrumb;
