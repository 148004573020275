import React, {useState} from 'react'
import Spy from "../../../../img/contract/spy.svg"
import TextArea from '../../TextArea';
import SelectContrato from '../../SelectContrato';

const Step_6 = () => {
  const [tipo, setTipo] = useState([
    {option: "Infidelidade", name: "Infidelidade"},
    {option: "Localizar : (Pessoas/Veículos/Animais de Estimação)", name: "Localizar : (Pessoas/Veículos/Animais de Estimação)"},
    {option: "Dossiê (Detalhar)", name: "Dossiê (Detalhar)"},
    {option: "Levantamento de Informações (Detalhar)", name: "Levantamento de Informações (Detalhar)"},
    {option: "Teste de Fidelidade", name: "Teste de Fidelidade"}
  ])

  return (
    <div className="step-1">
      <div className="step-header">
        <img src={Spy} alt="Espiao" />
        <h4 className="mb-0 ml-5">Características do Investigado(a)</h4>
      </div>
      <div className="form-row w-100">
        <div className="col-md-12">
          <TextArea 
            name="form_infor_caracteristica_investigado" 
            label="Características Físicas do Investigado(a):"
            placeholder="Características do investigado(a) (Idade Aproximada, Altura, Peso Aproximado, Cor da Pele, Cor do Cabelo, Tatuagem e Outros" 
            rows="3"
          />
        </div>
      </div>
      <div className="form-row w-100">
        <div className="col-md-12">
          <TextArea 
            name="form_locais_suspeitas_frequentados_investigado" 
            label="Locais Frequentados Pelo Investigado(a):"
            placeholder="Ex.: Acadêmia, Cursos/Faculdade, Restaurantes, etc." 
            rows="3"
          />
        </div>
      </div>
      <div className="form-row w-100">
        <div className="col-md-12">
          <TextArea 
            name="form_pessoas_suspeitas_frequentados_investigado" 
            label="Pessoas Suspeitas:"
            placeholder="" 
            rows="3"
          />
        </div>
      </div>
      <div className="form-row w-100">
        <div className="col-md-12">
          <SelectContrato 
              name="form_objetivo_investigacao_investigado" 
              label="Objetivo da Investigação" 
              data={tipo}
            />
        </div>
      </div>

      <div className="form-row w-100">
        <div className="col-md-12">
          <TextArea 
            name="form_relate_resumidamente__investigado" 
            label="Relate Resumidamente o Caso (Opcional)"
            placeholder="" 
            rows="3"
          />
        </div>
      </div>
    </div>
  )
}

export default Step_6;