import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";

import Bg from "../img/services/bg4.jpg"
import LocalizarPessoas from "../components/LocalizarPessoas";

import BtWhatsapp from "../components/ButtonWhatsapp";

const PessoasBreadcrumb = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb
          menu="Serviços"
          name="Encontrar pessoas" 
          bg={Bg}
        />
        <LocalizarPessoas />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default PessoasBreadcrumb;
