import React, { useState, useEffect } from "react";
import img1 from "../img/team/conjugal.svg";
import img2 from "../img/team/filhos.svg";
import img3 from "../img/team/empresarial.svg";
import img4 from "../img/team/localizacao.svg";
import img5 from "../img/team/relacionamento_virtual.svg";
import img6 from "../img/team/cuidador.svg";
import { Link, useParams } from "react-router-dom";
import Utilidade from "../context/Unidades"
import { FaVolumeUp } from "react-icons/fa";
import HandleMask from "../context/HandleMask";

const Service = () => {
  const [contatos, setContatos] = useState([])
  const { uf } = useParams()

  useEffect(() => {
    setContatos(Utilidade.renderTelVisivel(uf))
  }, [])

  return (
    <>
      <div className="waves-container">
        <div class="waves-3">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
          </svg>
        </div>
      </div>
      <div
        id="merox-team-area"
        className="merox-team-area mt-50 mb-0 wow fadeInUp"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
                <h4>Serviços</h4>
                <h3>Principais</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                  <img src={img1} alt="team" />
                  <h4>Investigação Conjugal</h4>
                  <span>Situações que geram
                    desconfiança por parte do cônjuge, namorado (a) ou
                    companheiro (a)...</span>
                </div>
                <div className="team-content">
                  <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_conjugal"}`}><span>Saiba mais</span></Link>
                  <div className="team-social-box">
                    {contatos.map(value =>
                      <>
                        {value.principal
                          ? <a href={`tel:${value.contato}`}>
                            <i className="fa fa-phone"></i>
                            {value.contato}
                          </a>
                          : <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=${value.contato}`}>
                            <i className="fa fa-whatsapp"></i>
                            {HandleMask(value.contato)}
                          </a>
                        }
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                  <img src={img2} alt="team" />
                  <h4>Investigação de Filhos</h4>
                  <span>Profissionais preparados para acompanhar o ritmo agitado dos jovens. Faça contato e conheça esse serviço.</span>
                </div>
                <div className="team-content">
                  <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_filhos"}`}><span>Saiba mais</span></Link>
                  <div className="team-social-box">
                    {contatos.map(value =>
                      <>
                        {value.principal
                          ? <a href={`tel:${value.contato}`}>
                            <i className="fa fa-phone"></i>
                            {value.contato}
                          </a>
                          : <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=${value.contato}`}>
                            <i className="fa fa-whatsapp"></i>
                            {HandleMask(value.contato)}
                          </a>
                        }
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                  <img src={img3} alt="team" />
                  <h4>Investigação Empresarial</h4>
                  <span>Construa processos judiciais sólidos e com mais evidências.</span>
                </div>
                <div className="team-content">
                  <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_empresarial"}`}><span>Saiba mais</span></Link>
                  <div className="team-social-box">
                    {contatos.map(value =>
                      <>
                        {value.principal
                          ? <a href={`tel:${value.contato}`}>
                            <i className="fa fa-phone"></i>
                            {value.contato}
                          </a>
                          : <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=${value.contato}`}>
                            <i className="fa fa-whatsapp"></i>
                            {HandleMask(value.contato)}
                          </a>
                        }
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
            </div>


            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                  <img src={img4} alt="team" />
                  <h4>Encontrar pessoas</h4>
                  <span>Profissionais experientes em investigação estratégica e acesso as mais novos processos de investigação.</span>
                </div>
                <div className="team-content">
                  <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/localizacao_pessoas"}`}><span>Saiba mais</span></Link>
                  <div className="team-social-box">
                    {contatos.map(value =>
                      <>
                        {value.principal
                          ? <a href={`tel:${value.contato}`}>
                            <i className="fa fa-phone"></i>
                            {value.contato}
                          </a>
                          : <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=${value.contato}`}>
                            <i className="fa fa-whatsapp"></i>
                            {HandleMask(value.contato)}
                          </a>
                        }
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                  <img src={img5} alt="team" />
                  <h4>Relacionamento virtual</h4>
                  <span>Apuraçao de de casos suspeitos para maior segurança do contratado.</span>
                </div>
                <div className="team-content">
                <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/relacionamento_virtual"}`}><span>Saiba mais</span></Link>
                  <div className="team-social-box">
                  {contatos.map(value =>
                      <>
                        {value.principal
                          ? <a href={`tel:${value.contato}`}>
                              <i className="fa fa-phone"></i>
                              {value.contato}
                            </a>
                          : <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=${value.contato}`}>
                              <i className="fa fa-whatsapp"></i>
                              {HandleMask(value.contato)}
                            </a>
                        }
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                  <img src={img6} alt="team" />
                  <h4>Investigação de Cuidador de Idosos</h4>
                  <span>Analisamos qualquer suspeita de agressões e maus tratos. Prezamos pela segurança do idoso ou ente querido.</span>
                </div>
                <div className="team-content">
                  <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_cuidador_idoso"}`}><span>Saiba mais</span></Link>
                  <div className="team-social-box">
                    {contatos.map(value =>
                      <>
                        {value.principal
                          ? <a href={`tel:${value.contato}`}>
                            <i className="fa fa-phone"></i>
                            {value.contato}
                          </a>
                          : <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=${value.contato}`}>
                            <i className="fa fa-whatsapp"></i>
                            {HandleMask(value.contato)}
                          </a>
                        }
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
