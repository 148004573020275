import React, { useEffect } from "react";
import Aos from "aos";
import 'aos/dist/aos.css';

import details from "../img/services/4.jpg";
import business from "../img/blog/invetigacao_funcionario.jpg";
import business2 from "../img/services/empresarial.jpg";
import carmem from "../img/services/carmem-1.webp";
import carmem2 from "../img/services/carmem-2.webp";
import carmem3 from "../img/services/carmem-3.webp";

const Empresarial = () => {

    useEffect(() => {
        Aos.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
        });
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div id="merox-blog-area" className="merox-blog-area mt-100 mb-110">

                <div className="empresarial-area">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h4>Proteja o Futuro do Seu Negócio com Nossa Expertise em Investigação Empresarial</h4>
                                <h3 className="empresarial-title mt-30" data-aos="fade-up">Investigação Empresarial</h3>
                                <div className="mb-50" data-aos="fade-left">
                                    <p>No mundo corporativo, a informação é um dos ativos mais valiosos. Seja para proteger seu negócio de fraudes, identificar comportamentos antiéticos ou garantir a integridade de seus parceiros comerciais, nossa equipe de detetives particulares está aqui para fornecer a você as informações cruciais para tomar decisões informadas e seguras.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="empresarial-area">
                    <div className="row">
                        <div className="col-12">
                            <div className="empresarial-img-div" data-aos="zoom-in">
                                <img className="empresarial-img" src={details} alt="img" />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="empresarial-area">
                    <div className="row">
                        <div className="col-12">
                            <div className="empresarial-img-div" data-aos="zoom-in">
                                <img className="empresarial-img" src={carmem2} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="empresarial-parallax empresarial-parallax-img" style={{backgroundImage: `url(${business2})`}}></div> */}

                {/* <div className="empresarial-parallax empresarial-parallax-img" style={{backgroundImage: `url(https://www.davecoulter.com/wp-content/uploads/2022/12/6-Important-Things-When-Choosing-a-Business-Location.jpg)`}}>
                </div> */}

                <div className="empresarial-area">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title" data-aos="fade-up">
                                <h2 className="empresarial-title">Nossos Serviços de Investigação Empresarial Incluem:</h2>
                                <div className="mt-50 mb-50" data-aos="fade-right">
                                    <h4>Investigação de Fraudes e Desvios:</h4>
                                    <p>Detectamos e coletamos evidências de fraudes financeiras, desvios de recursos e outras irregularidades que possam comprometer a saúde financeira da sua empresa.</p>
                                </div>
                                <div className="mt-50 mb-50" data-aos="fade-left">
                                    <h4>Due Diligence:</h4>
                                    <p>Realizamos uma análise detalhada de futuros parceiros comerciais, fusões e aquisições, garantindo que você tenha um panorama claro e preciso antes de tomar qualquer decisão estratégica.</p>
                                </div>
                                <div className="mt-50 mb-50" data-aos="fade-right">
                                    <h4>Verificação de Antecedentes:</h4>
                                    <p>Avaliamos o histórico de funcionários, candidatos a emprego e outros indivíduos relevantes para a sua empresa, assegurando que você trabalhe com pessoas confiáveis e qualificadas.</p>
                                </div>
                                <div className="mt-50 mb-50" data-aos="fade-left">
                                    <h4>Monitoramento de Concorrentes:</h4>
                                    <p>Coletamos informações estratégicas sobre seus concorrentes, ajudando você a identificar oportunidades e ameaças no mercado e a se manter sempre um passo à frente.</p>
                                </div>
                                <div className="mt-50 mb-50" data-aos="fade-right">
                                    <h4>Investigações Internas:</h4>
                                    <p>Investigamos comportamentos suspeitos ou antiéticos dentro da sua organização, como assédio, roubos internos, e vazamentos de informações confidenciais.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="empresarial-parallax empresarial-parallax-img" style={{backgroundImage: `url(${business})`}}>
                </div> */}

                {/* <div className="empresarial-area">
                    <div className="row">
                        <div className="col-12">
                            <div className="empresarial-img-div" data-aos="zoom-in">
                                <img className="empresarial-img" src={business2} alt="img" />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="empresarial-area">
                    <div className="row">
                        <div className="col-12">
                            <div className="empresarial-img-div" data-aos="zoom-in">
                                <img className="empresarial-img" src={carmem3} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="empresarial-area">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title" data-aos="fade-up">
                                <h2 className="empresarial-title">Por Que Escolher a Carmem Investigações?</h2>
                                <ul className="empresarial-list">
                                    <li className="mt-50 mb-50" data-aos="fade-up-left">
                                        <i className="fa fa-check"></i>
                                        <span className="empresarial-bold">Discrição e Confidencialidade:</span> Entendemos a importância da confidencialidade em nossas investigações. Operamos com o mais alto nível de discrição para proteger a reputação e os interesses da sua empresa.
                                    </li>
                                    <li className="mt-50 mb-50" data-aos="fade-up-right">
                                        <i className="fa fa-check"></i>
                                        <span className="empresarial-bold">Expertise e Experiência:</span> Nossa equipe é composta por profissionais altamente qualificados com vasta experiência em investigações empresariais, utilizando técnicas modernas e tecnologias avançadas.
                                    </li>
                                    <li className="mt-50 mb-50" data-aos="fade-up-left">
                                        <i className="fa fa-check"></i>
                                        <span className="empresarial-bold">Resultados Confiáveis:</span> Fornecemos relatórios detalhados e precisos, equipando você com todas as informações necessárias para tomar decisões informadas e proteger seus interesses corporativos.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="empresarial-area">
                    <div className="row">
                        <div className="col-12">
                            <div className="empresarial-effect-phrase" data-aos="fade-down">
                                <p>
                                    <span>&mdash;</span>
                                    Confie na nossa experiência para resguardar o seu negócio contra riscos e ameaças. Entre em contato conosco hoje e descubra como nossas soluções em investigação empresarial podem beneficiar a sua empresa.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Empresarial;
