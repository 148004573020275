import React, {useState} from 'react'
import Input from '../../Input';
import InputMask from '../../InputMask';
import SelectContrato from '../../SelectContrato';
import Personal from "../../../../img/contract/personal.svg"
import axios from "axios"
import Loding from '../../../Loding/loading';

const Step_2 = () => {
  const [cpfCnpj, setCpfCnpj] = useState(false)
  const [selectPessoa, setSelectPessoa] = useState([
    {option: "Pessoa física", name: "1 - Pessoa Física"},
    {option: "Pessoa Jurídica", name: "2 - Pessoa Jurídica"},
    {option: "Cliente do Exterior", name: "3 - Cliente do Exterior"}
  ])
  const [selectAgente, setSelectAgente] = useState([
    {option: "Carmem Silva", name: "1 - Carmem Silva" },
    {option: "Lúcia Silva", name: "2 - Lúcia Silva" },
    {option: "Gabriela Silva", name: "3 - Gabriela Silva" },
    {option: "Vitória Silva", name: "4 - Vitória Silva" },
    {option: "Michelle Silva", name: "5 - Michelle Silva" },
    {option: "Wellington Silva", name: " 6 - Wellington Silva" },
    {option: "Ana Silva", name: "7 - Ana Silva" },
  ])
  const [selectTypeService, setSelectTypeService] = useState([
    {option: "Levantamento de Informações", name: "1 - Levantamento de Informações"},
    {option: "Acompanhamento de rotina", name: "2 - Acompanhamento de Rotina / Monitoramento Físico"},
    {option: "Acompanhamento / Levantamento de informações", name: "3 - Acompanhamento / Levantamento de Informações"},
    {option: "Dossiê", name: "4 - Dossiê"},
    {option: "Encontrar pessoas, bens ou animais", name: "5 - Encontrar Pessoas, Bens ou Animais"},
    {option: "Varredura", name: "6 - Varredura"}
  ])
  const [address, setAddress] = useState({})
  const [addressCommercial, setAddressCommercial] = useState({})
  const [load, setLoad] = useState(false)

  const [ufs, setUFs] = useState([
    {option: "AC", name: "Acre"},
    {option: "AL", name: "Alagoas"},
    {option: "AM", name: "Amazonas"},
    {option: "AP", name: "Amapá"},
    {option: "BA", name: "Bahia"},
    {option: "CE", name: "Ceará"},
    {option: "DF", name: "Distrito Federal"},
    {option: "ES", name: "Espírito Santo"},
    {option: "GO", name: "Goiás"},
    {option: "MA", name: "Maranhão"},
    {option: "MG", name: "Minas Gerais"},
    {option: "MS", name: "Mato Grosso do Sul"},
    {option: "MT", name: "Mato Grosso"},
    {option: "PA", name: "Pará"},
    {option: "PB", name: "Paraíba"},
    {option: "PE", name: "Pernambuco"},
    {option: "PI", name: "Piauí"},
    {option: "PR", name: "Paraná"},
    {option: "RJ", name: "Rio de Janeiro"},
    {option: "RN", name: "Rio Grande do Norte"},
    {option: "RO", name: "Rondônia"},
    {option: "RR", name: "Roraima"},
    {option: "RS", name: "Rio Grande do Sul"},
    {option: "SC", name: "Santa Catarina"},
    {option: "SE", name: "Sergipe"},
    {option: "SP", name: "São Paulo"},
    {option: "TO", name: "Tocantins"},
  ]);


  const handleCep = (event) => {
    setLoad(true)
    const cepConvert = event.target.value.replace('.', '')
    const api = axios.create({
      baseURL: "https://viacep.com.br/ws"
    })
    api.get(`/${cepConvert}/json`)
      .then(({data}) =>{
        if ("erro" in data){
          alert("Não encontramos o endereço referente ao CEP.")
          setLoad(false)
        }else{
          if(event.target.id === 'comercial'){
            setAddressCommercial({
              logradouro: data.logradouro,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf
            })
          }else{
            setAddress({
              logradouro: data.logradouro,
              bairro: data.bairro,
              cidade: data.localidade,
              uf: data.uf
            })
          }
        }
        setLoad(false)
       })
       .catch(err => {
         alert("Digite o CEP corretamente.")
         setLoad(false)
        })   
  }
  
  return (
    <div className="step-1">
      <div className="step-header">
        <img src={Personal} alt="Personal" />
        <h4 className="mb-0 ml-5">Informações do contratante</h4>
      </div>
      
      <div className="form-row w-100">
        <div className="col-md-4">
          <SelectContrato 
            name="form_agente" 
            label="Nome do profissional que lhe atendeu" 
            data={selectAgente}
            />
        </div>
        <div className="col-md-4">
          <SelectContrato 
            name="form_tipo" 
            onChange={(e) => setCpfCnpj(e.target.value === 'Pessoa Jurídica')}
            label="Pessoa Física, Jurídica ou Exterior" 
            data={selectPessoa}
          />
        </div>
        <div className="col-md-4">
          <SelectContrato 
            name="form_servico_contratado" 
            label="Serviço contratado" 
            data={selectTypeService}
          />
        </div>
      </div>

      <div className="form-row w-100">
        <div className="col-md-6">
          <Input 
            name="form_nome" 
            label="Nome completo" 
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_profissao" 
            label="Profissão"
          />
        </div>
        <div className="col-md-2">
          <InputMask 
            name="form_cpf" 
            label={cpfCnpj ? "CNPJ" : "CPF"} 
            mask={cpfCnpj ? "99.999.999/9999-99" : "999.999.999-99"}
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_rg" 
            label="RG e Órgão Emissor"
          />
        </div>
      </div>
      
      <div className="form-row" style={{width: "100%"}}>
        <div className="col-md-2">
          <InputMask 
            name="form_cep_residencial" 
            label="CEP"
            mask="99.999-999"
            onBlur={handleCep}
          />
        </div>
        <div className="col-md-7">
          <Input 
            name="form_end_residencial" 
            label="Endereço*"
            defaultValue={address.logradouro} 
          />
        </div>
        <div className="col-md-1">
          <Input 
            name="form_n_residencial" 
            label="Número*" 
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_endereco_complemento" 
            label="Complemento"
          />
        </div>
      </div>

      <div className="form-row w-100">
        <div className="col-md-2">
          <Input 
            name="form_bairro_residencial" 
            label="Bairro*" 
            defaultValue={address.bairro}
          />
        </div>
        <div className="col-md-3">
          <Input 
            name="form_referencia_residencial" 
            label="Referência*" 
            defaultValue={address.referencia}
          />
        </div>
        <div className="col-md-2">
          <SelectContrato
            name="form_uf_residencial" 
            label="Estado*" 
            defaultValue={address.uf}
            data={ufs}
          />
        </div>
        <div className="col-md-3">
          <Input 
            name="form_cidade_residencial" 
            label="Cidade*" 
            defaultValue={address.cidade}
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_pais_residencial" 
            label="País*" 
          />
        </div>
      </div>  
      
      <div className="form-row w-100">
        <div className="col-md-2">
          <InputMask 
            name="form_cel" 
            label="Celular" 
            mask="(99) 99999-9999"
          />
        </div>
        <div className="col-md-2">
          <InputMask 
            name="form_tel_residencial" 
            label="Telefone Residencial" 
            mask="(99) 9999-9999"
          />
        </div>
        <div className="col-md-2">
          <InputMask 
            name="form_tel_comercial" 
            label="Telefone Comercial" 
            mask="(99) 9999-9999"
          />
        </div>
        <div className="col-md-6">
          <Input
            type="email" 
            name="form_email" 
            label="*E-mail"
            help="Nunca vamos compartilhar seu e-mail, com ninguém." 
        />
        </div>

        {/* <p>Endereço Comercial:</p> */}
        {/* <div className="form-row" style={{width: "100%"}}>
          <div className="col-md-2">
            <InputMask
              id="comercial"
              name="form_cep_comercial"
              label="CEP"
              mask="99.999-999"
              onBlur={handleCep}
            />
          </div>
          <div className="col-md-7">
            <Input 
              name="form_end_comercial"
              label="Endereço*"
              defaultValue={addressCommercial.logradouro} 
            />
          </div>
          <div className="col-md-1">
            <Input 
              name="form_n_comercial"
              label="Numero*" 
            />
          </div>
          <div className="col-md-2">
            <Input 
              name="form_endereco_complemento_comercial" 
              label="Complemento"
            />
          </div>
        </div> */}

      {/* <div className="form-row w-100">
        <div className="col-md-3">
          <Input 
            name="form_bairro_comercial" 
            label="Bairro*" 
            defaultValue={addressCommercial.bairro}
          />
        </div>
        <div className="col-md-3">
          <SelectContrato
            name="form_uf_comercial" 
            label="Estado*" 
            defaultValue={addressCommercial.uf}
            data={ufs}
          />
        </div>
        <div className="col-md-4">
          <Input 
            name="form_cidade_comercial" 
            label="Cidade*" 
            defaultValue={addressCommercial.cidade}
          />
        </div>
        <div className="col-md-2">
          <Input 
            name="form_pais_comercial" 
            label="País*" 
          />
        </div>
      </div>   */}
      </div>
      {load ? <Loding /> : null}  
    </div>
  )
}

export default Step_2;