import React, { useState, useEffect } from "react";

import details from "../img/services/5.jpg";


import MenuLeft from "./MenuLeft";


const LocalizarPessoas = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div id="menu-left" className="col-xl-4 col-lg-4">
              <MenuLeft />
            </div>
            <div className="col-xl-8 col-lg-8 pr-30">
              <div className="merox-blog-details-wraper">
                <div className="blog-details-content">
                  <div className="blog-details-img mb-4">
                    <img src={details} alt="img" />
                  </div>
                  <p> 
                    A <strong>investigação</strong> para <strong>encontrar pessoas </strong> 
                    trata-se de um serviço bastante complexo. As características dos serviços são amplos,
                    podendo ser um parente desaparecido há anos, um colega de trabalho que se 
                    afastou por contingências alheias à sua vontade, ou até mesmo um antigo amor.
                    <br />
                    <br />
                    De acordo com pesquisas, a maior parte dos responsáveis pela segurança pública 
                    no Brasil, não conseguem resolver 30% dos casos de desaparecimento. 
                    Os <strong>detetives particulares</strong> da <strong>Carmem Investigações </strong> 
                    já atingiram a meta de 70% no evento de <strong>Encontrar Pessoas</strong>.
                  </p>
                  
                  <h4 style={{color: "#9B0008"}}>Encontrar pessoas na Carmem Investigações</h4>

                  <p>
                    Os profissionais que integram a equipe da <strong>Carmem Investigações </strong> 
                    possuem experiência em investigações estratégicas (sempre dentro da legislação), 
                    aptidão extremamente relevantes no sucesso deste serviço.
                    <br />
                    <br />
                    Não espere mais o tempo passar, realize um orçamento sem compromisso e esclareça as 
                    dúvidas sobre os nossos serviços e <strong>encontre-a</strong> hoje mesmo.
                  </p>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalizarPessoas;
