import React, { useState, useEffect } from "react";

import details from "../img/services/3.jpg";


import MenuLeft from "./MenuLeft";


const Filhos = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])


  return (
    <>
      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div id="menu-left" className="col-xl-4 col-lg-4">
              <MenuLeft />
            </div>
            <div className="col-xl-8 col-lg-8 pr-30">
              <div className="merox-blog-details-wraper">
                <div className="blog-details-content">
                  <div className="blog-details-img mb-4">
                    <img src={details} alt="img" />
                  </div>
                  <p> 
                    Com a rotina agitada, muitos pais solicitam o serviço de 
                    <strong> investigação</strong> para acompanhar mais de perto as ações dos filhos. 
                    <br />
                    <br />
                    Ao contratar um <strong>detetive particular</strong> para iniciar a <strong>investigação </strong> 
                    de filhos, você estará demonstrando o seu amor e preocupação. 
                    <br />
                    <br />
                    Ao ficar mais tranquilo, estará mais preparado para orientar sobre 
                    problemas relacionados à infância e adolescência, mudanças de comportamento, 
                    afastamento de amigos mais próximos e até mesmo dos familiares.                    
                    <br />
                    <br />
                  </p>
                  <h4 style={{color: "#9B0008"}}>
                    Quando iniciar a <strong>Investigação de filhos</strong>?
                  </h4>
                  <p>
                    A <strong>investigação de filhos</strong> deve iniciar antes que o contato com pessoas
                    ou atividades suspeitas prejudiquem os sonhos e felicidade. 
                    O acesso a má influência está cada vez mais fácil e frequente, 
                    por isso, os pais precisam acompanhar os filhos.                                                          
                  </p>  
                  
                  <h4 style={{color: "#9B0008"}}>Quatro sinais que seus filhos precisam de <strong>investigação </strong></h4>

                  <p>
                    Para iniciar a <strong>investigação </strong> de <strong>filhos</strong>, os pais precisam 
                    identificar os cinco principais sinais de que eles estão 
                    escondendo alguma informação ou acontecimento. É nesse 
                    momento que os pais precisam intervir para saber o que está 
                    de fato ocorrendo. Os principais sinais a serem observados são:
                    <br />
                    <br />
                    <strong>1° Desvio de olhar:</strong>  ao perceber que os 
                    <strong> filhos</strong> estão evitando o contato visual com os pais, significa 
                    que eles estão escondendo informações. A fixação excessiva e o 
                    desvio de olhar durante um diálogo são ações que caracterizam 
                    mentira e a ocultação de informações.
                    <br />
                    <br />
                    <strong>2° Responder com perguntas: </strong> para ter tempo 
                    de inventar uma história, os <strong>filhos</strong> utilizam a tática da 
                    repetição de informações. O mais comum é utilizar a pergunta 
                    que você fez como resposta ao que foi questionado.
                    <br />
                    <br />
                    <strong>3° Uso excessivo das mãos:</strong> levar a mão ao 
                    rosto, mexer nas orelhas, brincar com o nariz ou passar a 
                    mão nos cabelos em excesso demonstra que os <strong>filhos</strong> estão 
                    mentindo ou ocultando informações importantes dos pais.
                    <br />
                    <br />
                    <strong>4° Estar sempre na defensiva:</strong> choros 
                    excessivos, reclamações por falta de confiança e reações 
                    explosivas quando questionados por alguma mudança, 
                    demonstram que os <strong>filhos</strong> estão mentindo.
                  </p>

                  <h4 style={{color: "#9B0008"}}>A <strong>investigação </strong> de filhos da Carmem Investigações</h4>

                  <p>
                    O <strong>detetive particular</strong> é a melhor ponte entre os resultados 
                    da <strong>investigação</strong> de <strong>filhos</strong> e comunicação aos pais. 
                    <br />
                    <br />
                    É sempre recomendado procurar empresas e profissionais qualificados. 
                    <br />
                    <br />
                    A Carmem Investigações irá comprovar, de maneira rápida, as 
                    suspeitas que colocam os jovens em situações de risco. 
                    Após a <strong>investigação</strong> de <strong>filhos</strong>, a 
                    família terá mais possibilidade de prevenir problemas com 
                    vícios e dar conselhos sobre as más companhias.
                    <br />
                    <br />
                    {/* Nossa equipe está preparada para seguir o ritmo agitado e conectados dos jovens!
                    Realize um orçamento sem compromisso e esclareça as dúvidas sobre os nossos serviços de investigação .

                    O <strong>detetive particular</strong> é a melhor ponte entre os resultados 
                    da <strong>investigação </strong> de <strong>filhos</strong> e comunicação aos pais. Normalmente, 
                    os principais motivos das mudanças de comportamento dos <strong>filhos </strong> 
                    estão relacionados a bullying, envolvimento com drogas, 
                    abusos ou comportamentos agressivos com outras pessoas. 
                    Por isso, é recomendado procurar empresas e profissionais qualificados.
                    <br />
                    <br />
                    A Carmem Investigações irá comprovar, de maneira rápida, as 
                    suspeitas de envolvimento com drogas ou pessoas que colocam 
                    os jovens em situações de risco. Após a <strong>investigação </strong> de <strong>filhos</strong>, 
                    a família terá mais possibilidade de prevenir problemas com 
                    vícios e dar conselhos sobre as más companhias.
                    <br />
                    <br />
                    Nossa equipe está preparada para seguir o ritmo agitado e 
                    conectados dos jovens! 
                    <br />
                    <br /> */}
                    Realize um orçamento sem compromisso e esclareça as dúvidas 
                    sobre os nossos serviços de <strong>investigação </strong>.
                  </p>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filhos;
