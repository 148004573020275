import React , {useEffect, useRef} from 'react';
import {useField} from '@unform/core';

const Input = ({name, label, help, ...rest}) => {
  const inputRef = useRef(null)
  const {fieldName, defaultValue, registerField, error} = useField(name)

  useEffect(()=>{
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  },[fieldName, registerField])

  return (
    <div className="form-group" style={{width: "100%"}}>
      <label for={fieldName}>{label}</label>
      <input
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        className={error ? 'form-control is-invalid': 'form-control'}
        {...rest}
      />
      {!!help
        ? error ? null : <small class="form-text text-warning">{help}</small>
        : null
      }
      
      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  )
}
export default Input;