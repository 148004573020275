import React, { useCallback, useEffect, useRef} from 'react';
import {useField} from '@unform/core';



const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;


const CurrencyInput = ({
	name, label,
  max = Number.MAX_SAFE_INTEGER,
  onValueChange,
  value, ...rest}) => {

	const inputRef = useRef(null)
  const {fieldName, defaultValue, registerField, error} = useField(name)

  useEffect(()=>{
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
	},[fieldName, registerField])
	
	const valueAbsTrunc = Math.trunc(Math.abs(value));
  if (value !== valueAbsTrunc || !Number.isFinite(value) || Number.isNaN(value)) {
    throw new Error(`invalid value property`);
  }
  const handleKeyDown = useCallback(
    (e)=> {
      const { key, keyCode } = e;
      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return;
      }
      const valueString = value.toString();
      let nextValue;
      if (keyCode !== DELETE_KEY_CODE) {
        const nextValueString = value === 0 ? key : `${valueString}${key}`;
        nextValue = Number.parseInt(nextValueString, 10);
      } else {
        const nextValueString = valueString.slice(0, -1);
        nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
      }
      if (nextValue > max) {
        return;
      }
      onValueChange(nextValue);
    },
    [max, onValueChange, value]
  );
  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, []);
  const valueDisplay = (value / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
	});
	
	return (
		<div  className="form-group" style={{width: "100%"}}>
		<label htmlFor={fieldName}>{label}</label>
		<input
				id={fieldName}
				ref={inputRef}
				defaultValue={defaultValue}
				{...rest}
				inputMode="numeric"
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				value={valueDisplay}
        className={error ? 'form-control is-invalid': 'form-control'}
		/>
		{error && <span className="invalid-feedback">{error}</span>}
		</div>
	)
}
export default CurrencyInput;
