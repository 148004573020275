import React, { useState, useEffect, useRef } from "react";

import { Form } from '@unform/web'
import InputSearch from './Form/InputSearch';

import { Link, useParams } from "react-router-dom";

import blogimg2 from "../img/blog/2.webp";
import blogimg3 from "../img/blog/3.webp";
import blogimg4 from "../img/blog/4.webp";
import blogimg5 from "../img/blog/5.webp";
import blogimg6 from "../img/blog/6.webp";

import MenuRight from "./MenuRight"


const BlogList = () => {
  const {uf} = useParams()
  const formRef = useRef(null);
  const [search, setSearch] = useState("")
  const [listFilter, setListFilter] = useState([])
  const [list, setList] = useState([
    { img: blogimg6,
      url: "6",
      date: "Investigação", 
      title: "Fraudes Corporativas",
      mesage: "Análise de dados e também os melhores métodos investigativos para detectar todas as letras miúdas de crimes e desvios, desde pequenos furtos de produtos até grandes fraudes financeiras que estão acontecendo em sua empresa. O serviço de investigação consiste em ações discretas, dentro e fora da empresa contratante, por meio de análise documental, informática forense, informática forense, análise de dados informáticos, infiltração disfarçada de agente, acompanhamento pessoal ou outro método investigativo que se faça necessário para detectar a fraude e seus autores. As empresas brasileiras sofrem diariamente um verdadeiro assalto aos seus cofres, desembolsados por pessoas tidas como confiáveis, seus próprios funcionários, sócios e executivos, na esmagadora maioria das vezes, que agem de absoluta má-fé e causam grandes prejuízos ao empresário."
    },
    { img: blogimg5,
      url: "5",
      date: "Investigação", 
      title: "Funcionários",
      mesage: "serviço de investigação de funcionários para averiguação de atividades suspeitas, indícios de fraudes ou qualquer situação em relação aos empregados da empresa cliente com o fim de identificar, minimizar, eliminar e, se possível, recuperar as perdas financeiras ocorridas. A investigação de funcionários é realizada sempre na tentativa de busca de provas da eventual fraude ocorrida, para que possa haver desligamento dos empregados envolvidos da empresa, com justa causa, sempre que possível, tentando minimizar mais gastos e perdas."
    },
    { img: blogimg4,
      url: "4",
      date: "Investigação", 
      title: "Executivos",
      mesage: "Investigamos toda atividade suspeita, indícios de fraudes ou qualquer situação do sócio ou executivo de sua empresa com o fim de minimizar, interromper e recuperar perdas financeiras. Agimos, inclusive, na tentativa de busca de provas de fraudes para uma futura dissolução da sociedade empresarial. Ao iniciar seu negócio, o empresário necessita, na ampla maioria das vezes, de uma ou algumas pessoas para dividir as dificuldades e, também, os lucros de seu empreendimento. Geralmente, escolhem-se pessoas que o complementem. Enquanto um é melhor gestor comercial, outro é melhor na técnica objeto do negócio. Não importa o porquê, a relação de uma sociedade empresarial deve ser pautada na confiança. Muitas vezes, a confiança é quebrada, deixa de existir. Aquela pessoa que deveria ser uma parceira no crescimento do negócio acaba se tornando uma concorrente dentro da própria empresa, cometendo fraudes, desvios monetários, captação irregular de clientes, roubo de objetos, espionagem industrial, tudo de forma a causar prejuízo, ou até mesmo, levar a companhia à falência."
    },
    { img: blogimg3,
      url: "3",
      date: "Investigação", 
      title: "Fornecedores",
      mesage: "Serviço de investigação com objetivo de buscar, analisar e interromper possíveis fraudes, esquemas ou subornos para favorecimento da empresa fornecedora dentro da empresa cliente. A Sabra utiliza ações de investigação que melhor se encaixem com o caso concreto e conta com uma equipe qualificada e treinada para serem feitas análises do material e documentos colhidos no decorrer de cada investigação. Sempre com foco na identificação e eliminação do problema enfrentado com o fornecedor. As empresas sofrem diariamente desfalques aos seus cofres, realizados, muitas vezes, com participação de funcionários e fornecedores tidos como confiáveis."
    },
    { img: blogimg2,
      url: "2",
      date: "Perícia Forense", 
      title: "Celulares, Computadores e Tablets",
      mesage: "Serviço de perícia forense computacional para coleta de provas de fraudes e crimes diversos praticados através de computadores no âmbito corporativo. Perícia executada com métodos científicos desde a coleta dos dados do computador periciado até a preservação das provas e indícios identificados. A perícia digital computacional executada pelos peritos forenses da Sabra podem ser utilizadas na esfera judicial como prova de fraudes e práticas ilícitas cometidas por funcionários, executivos e diretores de empresas. O Tribunais de Justiça brasileiros e internacionais têm considerado que o conteúdo de computadores e notebooks corporativos cedidos pela empresa aos seus funcionários para execução de suas tarefas, mesmo que fora do ambiente de trabalho, é de propriedade e responsabilidade do empregador. As cortes de justiça brasileiras têm considerado que o conteúdo de celulares concedidos pela empresa ao empregado para execução de suas tarefas, mesmo que fora do ambiente de trabalho, é de propriedade e responsabilidade do empregador. Isso significa que o conteúdo desses dispositivos poderá ser utilizado para produção de prova judicial em favor da empresa quando for necessário. Os softwares de perícia forense utilizados são internacionalmente reconhecidos em coletar dados de forma forense com aceitação legal pelos Tribunais brasileiros. Por outro, para utilização jurídica, a forma da coleta e análise desse conteúdo deve obedecer a padrões aceitos por nossos Tribunais."
    }    
  ])
  
  useEffect(()=>{
    setListFilter(handleFilter(list, search))
    console.log("Pesquisa", listFilter)
  },[search])

  function handleFilter(array, string) {
    return array.filter(o =>
      Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase()))
    )
  }

  const handleSearch = (data) => {
    setSearch(data.search)
  }

  return (
    <>
      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 pr-30">
              {listFilter.map(value => 
                <div className="col-xl-12">
                  <div className="single-blog-wraper mb-30  wow fadeInUp">
                    <div className="blog-img">
                      <img src={value.img} alt="blog-img" />
                    </div>
                    <div className="single-blog-content">
                      <div className="blog-date">{value.date}</div>
                      <h4>
                        <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/blog/" + value.url}`} className="post-title">
                          {value.title}
                        </Link>
                      </h4>
                      <p>
                        {value.mesage}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>  
            <div className="col-xl-4 col-lg-4">
              <MenuRight>
              <div className="single-sid-wdg sm-mt-30">
                <Form onSubmit={handleSearch} ref={formRef} className="wdg-search-form">
                  <InputSearch type="text" placeholder="Pesquisar " name="search"/>
                  <button className="submit-btn" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </Form>
              </div>
              </MenuRight>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogList;
