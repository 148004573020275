import React from "react";
import { Link } from "react-router-dom";
import img1 from "../img/curso/1.webp";

import destaque from "../img/curso/3.webp"
import inscrisao from "../img/curso/2.webp"

import material from "../img/curso/material.svg"
import investimento from "../img/curso/inv.svg"
import acesso from "../img/curso/dis.svg"
import pagamento from "../img/curso/car.svg"


const CursoDetetive = () => {
  return (
    <>
      <div className="pt-50 ">
        <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="section-title">
                  <h4>TORNE-SE UM DETETIVE DE ALTA QUALIDADE!</h4>
                  <h3>POR QUE ESCOLHER A CARMEM INVESTIGAÇÕES?</h3>
                  <span className="wow fadeInUp mb-50">
                    <p>Além de ajudar nas investigações pessoais e empresariais, a equipe da Carmem Investigações te ensina todas as expertises de um detetive particular em vários cursos! As aulas podem ser acessadas em qualquer dispositivo e horário.</p>
                  </span>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="single-team-wrapper text-center">
                  <div className="team-img">
                    <img src={material} alt="material" />
                    <h4>Material Didático</h4>
                    <span>Os cursos possuem excelente material didático e profissionais com experiência de mercado em casos de investigações pessoais e empresariais.</span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="single-team-wrapper text-center">
                  <div className="team-img">
                    <img src={investimento} alt="team" />
                    <h4>Baixo Investimento</h4>
                    <span>Os cursos de detetive particular possuem baixo investimento. Entre em contato para verificar o valor do investimento.</span>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="single-team-wrapper text-center">
                  <div className="team-img">
                    <img src={acesso} alt="team" />
                    <h4>Acesso rápido</h4>
                    <span>O material didático e as aulas são todas digitais. Você pode estudar sem sair de casa!</span>
                  </div>
                </div>
              </div>
              
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="single-team-wrapper text-center">
                  <div className="team-img">
                    <img src={pagamento} alt="team" />
                    <h4>Pagamento facilitado</h4>
                    <span>O pagamento pode ser realizado com Cartões de Crédito, Débito e muito mais.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-espiao-area-funcoes  mb-80 text-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 mx-auto col-lg-6 col-md-12">
              <div className="appespiao-content text-left pt-50 ">
                <div className="section-title">
                  <h4>NÃO PERCA MAIS TEMPO!</h4>
                  <h3>ESCOLHA O MELHOR CURSO</h3>
                </div>
                <span className="wow fadeInUp mb-50">
                  Ao optar pelos <strong>cursos de detetive particular</strong> da <strong>Carmem Investigações</strong>, você estará preparado para iniciar uma <strong>carreira</strong> no mercado que mais oferece <strong>oportunidades</strong> e remuneração qualificada.
                  <br />
                  <br />
                  Com baixo custo e com aulas acessíveis, você se tornará um <strong>detetive particular</strong> capacitado e competente!
                  <br />
                  <br />
                  <strong>Ao concluir o curso, você receberá:</strong>
                  <ul className="about-content-list mb-5">
                    <li>
                      <i className="fa fa-check"></i> <Link>Carteira de Identificação</Link>
                    </li>
                    <li>
                      <i className="fa fa-check"></i> <Link>Porta Documentos</Link>
                    </li>
                    <li>
                      <i className="fa fa-check"></i> <Link>Certificado de Conclusão</Link>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
            <div className="col-xl-6 mx-auto col-lg-6 col-md-12">
              <div className="curso-phone-img">
                <img src={img1} alt="cursos" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 mx-auto col-lg-5 col-md-12">
                <div className="appespiao-phone-img">
                  <img src={destaque} alt="carmen" />
                </div>
              </div>
              <div className="col-xl-7 mx-auto col-lg-7 col-md-12">
                <div className="appespiao-content text-left pt-50 ">
                  <div className="section-title">
                    <h4>Nossa Equipe</h4>
                    <h3>POR QUE ESCOLHER A CARMEM INVESTIGAÇÕES?</h3>
                  </div>
                  <span className="wow fadeInUp mb-50">
                    A investigação sempre foi um traço marcante da empresa <strong>Carmem Investigações.</strong> 
                    É uma empresa devidamente registrada, qualificada e experiente no ramo de <strong>investigações.</strong>
                    <br />
                    <br />
                    Nossa empresa familiar tem vasta experiência no mercado, 
                    parcerias com escritórios de advocacia e trabalho reconhecido pelo sigilo e confiabilidade. 
                    A equipe de detetives particulares já atingiram a meta de 70% do evento de <strong>encontrar pessoas.</strong> 
                    De acordo com pesquisas, a maior parte dos responsáveis pela segurança pública no Brasil 
                    não conseguem resolver 30% dos casos de desaparecimento.
                    <br />
                    <br />
                    Os cursos de detetive particular da <strong>Carmem Investigações</strong> têm como objetivo central formar profissionais excelentes e com capacidade para resolver conflitos pessoais, empresariais e comerciais.
                  </span>
                </div>
              </div>
            </div>
        </div>
      </div>
    
      <div className="app-espiao-area-funcoes text-center pb-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 mx-auto col-lg-4 col-md-12">
              <div className="appespiao-content text-left pt-50 ">
                <div className="section-title">
                  <h4>Cursos Online</h4>
                  <h3>VENHA ESTUDAR CONOSCO!</h3>
                </div>
                <span className="wow fadeInUp mb-50">
                Ficou interessado em nossos cursos de detetive online? 
                <a href="contact">Clique aqui</a> e preencha o formulário de contato.
                </span>
                {/* <Formulario /> */}
              </div>
            </div>
            <div className="col-xl-7 mx-auto col-lg-8 col-md-12">
              <div className="curso-phone-img">
                <img src={inscrisao} alt="team" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CursoDetetive;
