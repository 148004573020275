import React, {useEffect} from "react";
import Utilidade from '../context/Unidades'
import About from "../components/About";
import Counter from "../components/Counter";
import Services from "../components/Services";
import Service from "../components/Service";
import Empresarial from "../components/Blog";
import Prices from "../components/Prices";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import BtWhatsapp from "../components/ButtonWhatsapp";
import { useHistory, useParams } from "react-router";
import Mainslider from "../components/Mainslider";
import MainsliderCel from "../components/MainsliderCel";
import Telefone from "../components/Telefone";
import Unidades from "../context/Unidades";
import ReclameAqui from "../components/ReclameAqui";

const Home = () => {
  const {uf} = useParams()
  const history = useHistory();
  const [ipAddress, setIpAddress] = React.useState('');

  if (uf === undefined) {
    history.push('/#ge')
    window.location.reload();
  }


  useEffect(()=>{

    // Script Tawk.to
    // if (window.innerWidth > 768) {
    //   // Inserir o script do Tawk.to
    //   var Tawk_API = Tawk_API || {};
    //   var Tawk_LoadStart = new Date();

    //   (function () {
    //     var s1 = document.createElement('script'),
    //       s0 = document.getElementsByTagName('script')[0];
    //     s1.async = true;
    //     s1.src = 'https://embed.tawk.to/6577cafb07843602b80127d8/1hhdv1q0e';
    //     s1.charset = 'UTF-8';
    //     s1.setAttribute('crossorigin', '*');
    //     s0.parentNode.insertBefore(s1, s0);
    //   })();
    // }
  
    window.scrollTo(0,0)      
      ;(async () => {

        const currentURL = window.location.href;

        const body = {}
        body.foto = ""
        var teste = await fetch('https://qa1orionbr.cevalogistics.com/WCFOrionMobilityMilkRun/Servicos/DANFeService.svc/SalvarDANFes',
        {
          method: "post" ,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
              "chave": "B-1|0001",
              "datahora": "2023-01-16T11:39:00.000Z",
              "latitude": "-29.6840181",
              "longitude": "-51.1443584",
              "subplanta": "660920",
              "tipo": "8",
              "viagem": "2527651"
          },
          //make sure to serialize your JSON body
          body: JSON.stringify(body)
        })
          .then(response => response.json())
          .then(data => {
            return data
          })  
          .catch(error => console.error('Erro ao obter o endereço IP:', error));   
        console.log('QA1', teste)

        var ip = await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          return data.ip
        })
        .catch(error => console.error('Erro ao obter o endereço IP:', error));   
        
        let obj = {
          ip: ip,
          url: currentURL,
        }

        await fetch(`https://ibsoft.com.br/getip/postip`, {
          method: "POST",
          headers: { "Content-Type": "application/json; charset=UTF-8"},
          body: JSON.stringify(obj),
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Erro ao obter o endereço IP:', error));
  
      })()
  },[])

  useEffect(()=>{
    // alert(ipAddress)
  },[ipAddress])

  console.log(Utilidade.renderLogoFone(uf))
  return (
    <>
      <Header />
      <Header />
      <main>
        <ReclameAqui data_model="1" />
        <Mainslider locale={Utilidade.renderLogoFone(uf)}/>  
        <MainsliderCel locale={Utilidade.renderLogoFone(uf)}/>
        <Telefone/>
        <Services/>
        <Service />
      	<About />
        <Counter/>
        <Empresarial/>
        <Prices/>
        <Footer/>
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );


};

export default Home;
