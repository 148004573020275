import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Unidades from '../context/Unidades';
import { FaWhatsapp } from 'react-icons/fa';
import Chat from '../components/chat';
import Image from "../img/whatsapp/WhatsApp.png";

const BtWhatsapp = () => {
  const [open, setOpen] = useState(false);
  const [visibilityMsg, setVisibilityMsg] = useState("hidden");
  const [is_visible, setIs_Visible] = useState(true);
  const [whatsapp, setWhatsapp] = useState();
  const [textVisible, setTextVisible] = useState("hidden");
  const [whatsappMessage, setWhatsappMessage] = useState("");

  const { uf } = useParams()

  const location = useLocation();

  useEffect(() => {
    const whats = Unidades.renderTelVisivel(uf)
    whats.map(value => {
      if (value.whatsapp) {
        var link = "";

        const message = location.pathname === `${process.env.PUBLIC_URL + "/" + uf + "/investigacao_empresarial"}`
          ? "Olá, estou interessado em discutir a possibilidade de contratar seus serviços de investigação empresarial! Poderíamos agendar uma conversa para que eu possa entender melhor como vocês podem nos ajudar?"
          : " ";

        if (window.innerWidth <= 800) {
          link = `https://api.whatsapp.com/send/?phone=${value.contato.substring(1)}&text=${encodeURIComponent(message)}`;
        } else {
          link = `https://web.whatsapp.com/send/?phone=${value.contato.substring(1)}&text=${encodeURIComponent(message)}`;
        }

        setWhatsapp(link);
        setWhatsappMessage(message);
      }
    })
  }, [uf, location.pathname]);

  // useEffect(() => {
  //   visibilityMsg === "hidden"
  //   ? setTimeout(() => {
  //       setVisibilityMsg("visible")
  //     }, 3000)
  //   : setTimeout(() => {
  //       setVisibilityMsg("hidden")
  //     }, 3000)
  // },[visibilityMsg])

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setTextVisible("visible");
      } else {
        setTextVisible("hidden");
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const classWhatsapp = ['whats-div', 'whats-div-1', 'whats-div-2'];

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  return (
    <>
      {/* {is_visible && (
        <>
          <div className="whatsapp">
            <div class="whatsappMSG">
              <p class="balao2">Atendimento discreto</p>
            </div>
            <div>
              <a href={whatsapp} target="_blank"><FaWhatsapp className="icon mr-2" /></a>
            </div>
          </div>
        </>
        <div id="msg1" style={{visibility: `${visibilityMsg}`}}><p>Fale conosco via WhatsApp</p></div> 
      )} */}

      <div className={classWhatsapp[getRandomInt(classWhatsapp.length)]}>
        <a href={whatsapp} target="_blank" className="whats-btn">
          <img src={Image} alt="WhatsApp Icon" className="whats-icon" />
          <span className="whats-btn-text" style={{ visibility: `${textVisible}` }}>Fale conosco pelo WhatsApp</span>
        </a>
      </div>
    </>
  )
}


export default BtWhatsapp;