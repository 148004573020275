import React, { useState, useEffect } from "react";
import brand from "../img/logo-2.png";
import brand3 from "../img/logo-3.png";
import { Link, useParams } from "react-router-dom";
import Unidade from '../context/Unidades';
import HandleMask from '../context/HandleMask';
import Aos from "aos";
import 'aos/dist/aos.css';

const Footer = () => {
    const [contatos, setContatos] = useState([])
    const { uf } = useParams()

    useEffect(() => {
        setContatos(Unidade.renderContato(uf))
    }, []);

    console.log(uf);

    useEffect(() => {
        Aos.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: false,
        });
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <footer>
                <div className="waves-container">
                    <div class="waves-2">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                        </svg>
                    </div>
                </div>

                <div id="merox-footer-area" className="merox-footer-area pt-50 pb-70">
                    <div className="footer-area">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-single-wedget" data-aos="fade-right">
                                    <div className="custom-html-widget">
                                        <div className="footer-logo">
                                            <Link to="">
                                                <img
                                                    src={uf == "sp"
                                                        ? brand
                                                        : brand3
                                                    }
                                                    alt="footer-logo"
                                                    className="img-fluid"
                                                />
                                            </Link>
                                        </div>
                                        <p>
                                            A Carmem Investigações executa o seu trabalho com sigilo e responsabilidade. Estamos sempre atentos aos novos processos que auxiliam nas investigações e segurança dos nossos clientes.

                                        </p>
                                        <ul className="footer-social pb-5">
                                            <li>
                                                <a href="https://instagram.com/carmem_investigacoes?utm_medium=copy_link" target="_blank">
                                                    <i className="fa fa-instagram" style={{ fontSize: '50px' }}></i>
                                                </a>
                                            </li>
                                        </ul>
                                        {
                                            Unidade.renderLogoFone(uf).uf === 'ge'
                                                ? <h5 style={{ color: "#ffff", textAlign: 'center' }}>Atendimento em todo <b style={{ textTransform: "uppercase" }}>Brasil</b></h5>
                                                : <h5 style={{ color: "#ffff", textAlign: 'center' }}>
                                                    Detetive Particular em <b style={{ textTransform: "uppercase" }}>{Unidade.renderLogoFone(uf).uf}</b>
                                                </h5>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-single-wedget" data-aos="fade-up">
                                    <div className="widget_nav_menu">
                                        <h3>menu rápido</h3>
                                        <ul className="menu">
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/contact"}`}>Fale Conosco </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_conjugal"}`}>
                                                    Investigação conjugal
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_filhos"}`}>
                                                    Investigação de filhos
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_empresarial"}`}>
                                                    Investigação empresarial
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL + "/" + uf + "/localizacao_pessoas"}`}>
                                                    Encontrar Pessoas
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link
                                                    to={`${process.env.PUBLIC_URL + "/" + uf + "/relacionamento_virtual"}`}>
                                                    Relacionamento virtual
                                                </Link>
                                            </li> */}
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/investigacao_cuidador_idoso"}`}>
                                                    Investigação de cuidador de idosos
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/politica_privacidade"}`}>
                                                    Política de Privacidade
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-single-wedget" data-aos="fade-right">
                                    <div className="custom-html-widget">
                                        <h3>Contatos</h3>
                                        <ul className="contact-details">
                                            {contatos.map(value =>
                                                value.telefone.map(tel =>
                                                    tel.principal
                                                        ? <li className="phone-footer">
                                                            <a href={`tel:${tel.contato}`}>
                                                                <i className="fa fa-phone"></i>
                                                                {HandleMask(tel.contato)}
                                                            </a>
                                                        </li>
                                                        : null
                                                )
                                            )}

                                            {contatos.map(value =>
                                                <>
                                                    <li>
                                                        {value.address === null || !value.address
                                                            ? null
                                                            : <>
                                                                <i className="fa fa-map-marker"></i>
                                                                {value.address}
                                                                <br />
                                                                {value.city} {value.cep === null ? null : ` - ${value.cep}`}
                                                            </>
                                                        }

                                                    </li>
                                                    {value.telefone.length > 0
                                                        ? value.telefone.map(whats =>
                                                            whats.whatsapp
                                                                ? <li className="phone-footer">
                                                                    <a className="footer-whatsapp-desktop"
                                                                        href={`https://web.whatsapp.com/send/?phone=${whats.contato}`} target="_blank">
                                                                        <i className="fa fa-whatsapp"></i>
                                                                        {HandleMask(whats.contato)}
                                                                    </a>
                                                                    <a className="footer-whatsapp-mobile"
                                                                        href={`https://api.whatsapp.com/send?phone=${whats.contato}`} target="_blank">
                                                                        <i className="fa fa-whatsapp"></i>
                                                                        {HandleMask(whats.contato)}
                                                                    </a>
                                                                </li>
                                                                : null
                                                        )
                                                        : <br />
                                                    }
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="merox-copyright-area pt-30 pb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="footer-copyright text-center">
                                    <p>
                                        © 2020 Todos os direitos reservados a Carmem Investigações | CNPJ: {uf === 'sp' ? "39.761.835/0001-08" : "32.176.074/0001-70"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
