import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import icon1 from "../img/counter/1.svg";
import icon2 from "../img/counter/2.svg";
import icon3 from "../img/counter/3.svg";
import icon4 from "../img/counter/4.svg";
import CounterMobile from "./CounterMobile";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const Counter = () => {

  const { width } = useWindowSize();

  return width <= 768 ? (
    <CounterMobile />
  ) : (
    <>
      <div
        id="merox-counter-area"
        className="merox-counter-area mb-70 wow fadeInUp"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="about-single-counter text-center">
                <img src={icon1} alt="icon" />
                <h2 className="counter">
                  <CountUp start={0} end={5950} duration={2.75} />
                </h2>
                <span>Clientes Felizes</span>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="about-single-counter text-center">
                <img src={icon2} alt="icon" />
                <h2 className="counter">
                  <CountUp start={0} end={2500} duration={2.75} />
                </h2>
                <span>Parceiros Confiáveis</span>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="about-single-counter text-center">
                <img src={icon3} alt="icon" />
                <h2 className="counter">
                  <CountUp start={0} end={6580} duration={2.75} />
                </h2>
                <span>Projetos Realizados</span>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="about-single-counter text-center">
                <img src={icon4} alt="icon" />
                <h2 className="counter">
                  <CountUp start={0} end={36973} duration={2.75} />
                </h2>
                <span>Xícaras de Café</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Counter;
