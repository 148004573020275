import React from "react";
import servbg from "../img/services/services-bg.jpg";
import Formulario from "../components/Fomulario";

import { FaRegClock } from "react-icons/fa"
import { useParams } from "react-router-dom";

import Utilidade from "../context/Unidades"

const Services = () => {

  const { uf } = useParams()

  return (
    <>
      <div
        id="merox-services-area"
        className="merox-services-area pt-100 pb-100"
      >
        <div className="merox-services-area-img">
          <img src={servbg} alt="img" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 offset-xl-1 col-lg-5 order-2 wow fadeInRight">
              <div className="section-title lite">
                <h4 className="mobile-h4">Fale conosco</h4>
                <h3>Nós entramos em contato com você!</h3>
                <h5>
                  {Utilidade.renderLogoFone(uf).uf != 'ge'
                    ? <strong style={{ fontSize: '15px' }}>Detetive Particular em {Utilidade.renderLogoFone(uf).uf}</strong>
                    : null
                  }
                </h5>
              </div>
            </div>
            <div className="col-xl-6 col-lg-8 wow fadeInLeft">
              <div className="services-box">
                <Formulario />
                <div className="footer-form">
                  <FaRegClock size={30} />
                  <div>
                    <strong>HORÁRIO DE ATENDIMENTO:</strong>
                    <p>Segunda à sexta de 9:00 às 18:00 / Sábado de 9:00 às 13:00</p>
                  </div>
                </div>
                {Utilidade.renderLogoFone(uf).uf != 'ge'
                  ? <strong style={{ display: 'flex', textAlign: 'center', fontSize: '15px' }}>Detetive Particular em {Utilidade.renderLogoFone(uf).uf}</strong>
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
