import React , {useEffect, useRef} from 'react';
import {useField} from '@unform/core';

const InputSearch = ({name, label, help, ...rest}) => {
  const inputRef = useRef(null)
  const {fieldName, defaultValue, registerField, error} = useField(name)

  useEffect(()=>{
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  },[fieldName, registerField])

  return (
    <>
      <input
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {!!help
        ? error ? null : <small class="form-text text-warning">{help}</small>
        : null
      }
      {error && <span className="invalid-feedback">{error}</span>}
    </>
  )
}
export default InputSearch;