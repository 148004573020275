import React, { useEffect, useState } from "react";

import details from "../img/services/6.jpg";


import MenuLeft from "./MenuLeft";


const RelacionamentoVirutal = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div id="menu-left" className="col-xl-4 col-lg-4">
              <MenuLeft />
            </div>
            <div className="col-xl-8 col-lg-8 pr-30">
              <div className="merox-blog-details-wraper">
                <div className="blog-details-content">
                  <div className="blog-details-img mb-4">
                    <img src={details} alt="img" />
                  </div>
                  <p> 
                    É amizade ou namoro? Se for <strong>relacionamento virtual</strong>, é preciso tomar muito cuidado! De acordo com estudos, 90% das traições iniciam com trocas de mensagens em aplicativos como WhatsApp, chats de Instagram ou Facebook.
                    <br />
                    <br />
                    A internet está cheia de pessoas mal intencionadas e qualquer dado importante pode custar muito caro. Por isso é preciso ter muita atenção ao compartilhar informações pessoais, como endereço residencial ou comercial.
                  </p>
                  <h4 style={{color: "#9B0008"}}>
                    Como identificar uma infidelidade em um relacionamento virtual?
                  </h4>
                  <p>
                    Considerando que as ferramentas de comunicação também servem como espaço para paqueras ocultas, os cônjuges, namorados (as) e companheiros (as) irão demonstrar algumas mudanças no comportamento para ocultar um <strong>relacionamento virtual</strong>.
                  <br />
                  <br />
                  Os principais sinais de uma infidelidade em um <strong>relacionamento virtual</strong> são:
                  <ul className="about-content-list mb-5">
                      <li>
                        <i className="fa fa-check"></i> Uso de senhas no aparelho celular
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Mais tempo online em WhatsApp, Instagram e Facebook
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Não deixa o celular ao alcance de ninguém
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Não comenta sobre as atividades online
                      </li>
                      <li>
                        <i className="fa fa-check"></i> Irritação quando alguém chega perto do celular
                      </li>
                    </ul>
                  </p>  
                  
                  <h4 style={{color: "#9B0008"}}>A  investigação de relacionamento virtual na Carmem Investigações</h4>

                  <p>
                    A <strong>investigação</strong> de <strong>relacionamento virtual</strong> da <strong>Carmem Investigações</strong> utiliza de equipamentos e metodologia moderna para obter sucesso na entrega da apuração aos nossos clientes.
                    <br />
                    <br />
                    De acordo com recente estudo da Global Web Index, 42% dos visitantes do Tinder já são comprometidos. O que prova que o <strong>relacionamento virtual</strong> pode ser uma infidelidade, em sua grande maioria.
                    <br />
                    <br />
                    Para iniciar a <strong>investigação</strong>, o <strong>detetive particular</strong> irá criar situações nas redes sociais e aplicativos de relacionamentos para acompanhar o comportamento do investigado com o objetivo de monitorar as interações dele nos ambientes digitais.
                    <br />
                    <br />
                    Ao compartilhar informações pessoais com estranhos na internet ou suspeitar de uma infidelidade de um <strong>relacionamento virtual</strong>, procure os nossos  profissionais. Realize um orçamento sem compromisso e esclareça as dúvidas sobre os nossos serviços de <strong>investigação</strong>.
                  </p>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RelacionamentoVirutal;
