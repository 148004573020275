import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import Bg from "../img/about/banner.webp";
import moment from 'moment';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useHistory, useParams } from "react-router";

const ListIPs = () => {

  const {uf} = useParams()
  const [data, setData] = useState([])

  useEffect(async ()=>{
    
    await fetch(`https://ibsoft.com.br/getip/getallips`)
    .then(response => response.json())
    .then(data => {
      if (uf !='ge'){
        setData(data.filter(f=>f.url.includes('#'+uf)))
      }else{
        setData(data)
      }
  
    }
        )
    .catch(error => console.error('Erro ao obter o endereço IP:', error));

  },[])
  useEffect(()=>{
  },[data])

  return (
    <>
      <Header />
      <main>
        <Breadcrumb
          menu=""
          name="Relação de IP's que acessaram o site" 
          bg={Bg}
        />
        <div
          style={{padding:'20px'}}>
          <div
            style={{width:'100%',
                    textAlign:'center'}}
            >
              <ReactHTMLTableToExcel
              style={{margin:'10px'}}
              id="botao-exportar"
              className="btn btn-primary"
              table="tabela"
              filename="tabela_excel"
              sheet="sheet1"
              buttonText="Baixar Excel"
            />
          </div>
          <table id="tabela">
              <tr>
                <th>IP</th>
                <th>URL</th>
                <th>Data</th>
              </tr>
              {
                data.map(item => {
                  console.log(item)
                  return (
                    <tr>
                    <td>{item.ip}</td>
                    <td>{item.url}</td>
                    <td>{moment(item.date).format('DD/MM/YYYY hh:mm:ss')}</td>
                  </tr>

                  )
                  }
                )
              }
            </table>
          </div>
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default ListIPs;
