import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import Bg from "../img/privacidade/banner.jpg";
import PoliticaPrivacidade from "../components/PrivacyPolicy";

import BtWhatsapp from "../components/ButtonWhatsapp";

const Privacidade = () => {
    return (
        <>
            <Header />
            <main>
                <Breadcrumb
                    menu=""
                    name="Política de Privacidade"
                    bg={Bg}
                />
                <PoliticaPrivacidade />
                <Footer />
            </main>
            <BtWhatsapp />
            <Scrolltop />
        </>
    );
};

export default Privacidade;
