import React, { useRef, useState } from 'react';

import { Form } from '@unform/web'
import Input from './Form/Input';
import Select from './Form/Select'
import InputMask from './Form/InputMask';
import TextArea from './Form/TextArea';
import emailjs from 'emailjs-com'
import * as Yup from 'yup';
import Loding from '../components/Loding/loading';
import { useParams } from 'react-router-dom';


const Formulario = () => {
  const formRef = useRef(null);
  const [load, setLoad] = useState(false)
  const { uf } = useParams()

  const handleSubmit = async (data, { reset }) => {
    setLoad(true)
    try {
      const schema = Yup.object().shape({
        from_name: Yup.string().required("Campo obrigatório"),
        from_email: Yup.string().required("Campo obrigatório"),
        from_celular: Yup.string().required("Campo obrigatório"),
        from_mensagem: Yup.string().required("Campo obrigatório"),
      })

      await schema.validate(data, {
        abortEarly: false
      })

      const sendMail = await emailjs.send(
        'service_csvctcv', 'template_1f2cgme',
        {
          form_from: 'carmeminvestigacoesbr@gmail.com',
          from_name: data.from_name,
          from_email: data.from_email,
          from_celular: data.from_celular,
          from_h_contato: data.from_h_contato,
          from_mensagem: data.from_mensagem
        }, 'user_O4fwL6B75x4ac4xqdq3D1'
      )
      setLoad(false)
      alert('Contato enviado com sucesso!\n\nEnviamos uma copia para você.');
      reset()
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const erroMessages = {}

        err.inner.forEach(error => {
          erroMessages[error.path] = error.message;
        })

        formRef.current?.setErrors(erroMessages)
      }
      setLoad(false)
    }
  }

  return (
    <>
      {load ? <Loding /> : null}
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <Input
          type="text"
          name="from_name"
          label="Nome"
          placeholder=""

        />
        <Input
          type="email"
          name="from_email"
          label="E-mail"
        />
        <InputMask
          type="text"
          name="from_celular"
          label="Telefone"
          mask="(99) 99999-9999"
        />
        <Select
          name="from_h_contato"
          label="Horário para contato"
        />
        <TextArea
          name="from_mensagem"
          label="Mensagem"
        />
        <button className="btn btn-type-5 wow fadeInUp"> Enviar </button>
      </Form>

    </>
  )
}

export default Formulario;