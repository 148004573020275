import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Formulario from './Fomulario';
import Utilidade from "../context/Unidades";

import wdg1 from "../img/wdg/wdg-img-1.jpg";
import wdg2 from "../img/wdg/wdg-img-2.jpg";
import wdg3 from "../img/wdg/wdg-img-3.jpg";
import HandleMask from "../context/HandleMask"

const MenuRight = ({children}) => {
  const [contatos, setContatos] = useState([])
  const {uf} = useParams()

  useEffect(() => {
    setContatos(Utilidade.renderTelVisivel(uf))
  }, [])

  return (
    <>
        {children}
      <div className="single-sid-wdg">
        <div className="widget_recent_entries">
          <h3>Mais Contratados</h3>
          <div className="single-wdg-post">
            <div className="wdg-post-img">
              <Link to="#">
                <img
                  src={wdg1}
                  alt="blog thumbnail"
                  className="img-fluid"
                />
              </Link>
            </div>
            <div className="wdg-post-content">
              <h5>
                <Link to="#">
                  Dúvidas sobre seus Executivos
                </Link>
              </h5>
              <span>Atestado Falso</span>
            </div>
          </div>

          <div className="single-wdg-post">
            <div className="wdg-post-img">
              <Link to="#">
                <img
                  src={wdg2}
                  alt="blog thumbnail"
                  className="img-fluid"
                />
              </Link>
            </div>
            <div className="wdg-post-content">
              <h5>
                <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/blog/3"}`}>
                  Investigação de funcionários
                </Link>
              </h5>
              <span>Atestado Falso</span>
            </div>
          </div>

          <div className="single-wdg-post">
            <div className="wdg-post-img">
              <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/blog/2"}`}>
                <img
                  src={wdg3}
                  alt="blog thumbnail"
                  className="img-fluid"
                />
              </Link>
            </div>
            <div className="wdg-post-content">
              <h5>
                <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/blog/2"}`}>
                  Advogado(a) está com dificuldades em coletar provas?
                </Link>
              </h5>
              <span>Computadores e Celulares</span>
            </div>
          </div>
        </div>
      </div>

      <div className="single-sid-wdg sm-mt-30">
        <div className="widget_recent_entries">
          <h3>Fale Conosco</h3>
          <Formulario />
        </div>  
      </div>
      
      <div className="single-sid-wdg">
        <div className="widget_categories">
          <h3>Contatos</h3>
          <ul>
          {contatos.map(value => 
              <>
                {value.principal
                  ? <li>
                      <a href={`tel:${value.contato}`}>
                        <i className="fa fa-phone mr-3"></i>
                        <strong>{value.contato}</strong>
                      </a>
                    </li>
                  : <li>
                      <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send?phone=${value.contato}`} target="_blank">
                        <i className="fa fa-whatsapp mr-3"></i>
                        {HandleMask(value.contato)}
                      </a>
                    </li>
                }
              </>
            )}
          </ul>
        </div>
      </div>

      <div className="single-sid-wdg">
        <h3>Redes sociais</h3>
        <ul className="wdg-follow-us">
          <li>
            <a href="https://instagram.com/carmem_investigacoes?utm_medium=copy_link" target="_blank">
              <i className="fa fa-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default MenuRight;